@import '../../marketplace.css';

.root {
  position: relative;
}

.label {
  display: flex;
  align-items: center;
  padding-top: 6px;
}

.placeholder {
  margin-top: 0;
  color: var(--matterColorAnti);
}

.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper span {
  margin-right: 12px;
}

.wrapper span label {
  flex-direction: column-reverse;
}