@import '../../../marketplace.css';

:root {

    --button: {
        @apply --marketplaceButtonStyles;

        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        font-size: 13px;
        font-weight: bold;
        line-height: 0;
        padding: 0;

        border-radius: var(--borderRadiusMedium);

        min-height: 35px;
        height: 35px;

        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;

        @media (--viewportMedium) {
            -ms-flex-preferred-size: calc(50% - 25px);
            -webkit-flex-basis: calc(50% - 25px);
            flex-basis: calc(50% - 25px);
            min-height: 45px;
            height: 45px;
        }

        @media (--viewportLarge) {
            font-size: 18px;
            min-height: 50px;
            height: 50px;
        }
    }
}

.root {
    display: flex;
    flex-direction: column-reverse;

    text-align: center;

    @media(--viewportLarge) {
        flex-direction: row;
    }
}

.headerContent {
    width: 100%;
    background-color: var(--matterColorLight);
    border-radius: 0 0 0 0;
    height: fit-content;
    display: flex;
    flex-direction: column;

    padding: 24px 24px 24px 24px;

    margin: auto 0;

    @media (--viewportSmall) {
        padding: 50px 50px 50px 50px;
    }

    @media (--viewportMedium) {
        border-radius: 0 0 50px 0;
        padding: 25px 50px 25px 50px;
    }

    @media (--viewportLarge) {
        padding: 45px 75px 45px 150px;
    }

    @media (min-width: 1681px) {
        padding: 25px 200px 25px 200px;
    }
}

.headerMainTitle {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin-bottom: 15px;

    font-size: 28px;
    line-height: 1.1em;

    @media (--viewportMedium) {
        font-size: 32px;
    }

    @media (--viewportLarge) {
        margin-top: 64px !important;
        font-size: 42px;
    }

    @media (--viewportXLarge) {
        font-size: 56px;
    }
}

.headerSubTitle {
    font-family: var(--secondaryFontFamily);
    color: var(--matterColor);

    margin: 0 auto 32px;

    font-size: 24px;
    line-height: 1.5em;

    @media (--viewportMedium) {
        margin-bottom: 40px;
        width: 30%;
    }

    @media (--viewportXLarge) {
        padding-right: 15%;
        width: 100%;
    }
}

.signup {
    @apply --button;
    font-size: 16px;

    background-color: var(--marketplaceSecondaryColor);
    margin: 48px auto 48px;
    border-radius: 8px;

    @media (--viewportMedium) {
        border-radius: 15px;
        margin-bottom: 0;
        margin: 0 auto 150px;

        width: 256px;
    }
}

.grid {
    display: grid;
    column-gap: 12px;

    grid-template-columns: calc(30% - 12px) calc(35% - 6px) calc(35% - 6px);

    grid-template-areas:
        ". normal gofynder"
        "description normal gofynder"
        "description normal gofynder"
        "description normal gofynder"
        "description normal gofynder"
        "description normal gofynder"
        "description normal gofynder"
        "description normal gofynder";

    @media (--viewportMedium) {
        column-gap: 48px;
        grid-template-columns: calc(40% - 48px) calc(30% - 48px) calc(30% - 48px);
    }

}

.descriptionColumn {
    grid-area: description;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.normalColumn {
    grid-area: normal;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--matterColorAnti);
    border-radius: 25px;
    justify-content: flex-start;

}

.gofynderColumn {
    grid-area: gofynder;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--matterColor);
    border-radius: 25px;

    justify-content: flex-start;
}

.itemHeader {
    text-align: center;
    padding-top: 12px;
    min-height: 130px;
}

.itemPretitle {
    color: var(--matterColor);
    font-size: 12px;
    line-height: 1.5em;
    margin-bottom: 0;

    @media (--viewportMedium) {
        font-size: 14px;
    }

    @media (--viewportLarge) {
        font-size: 16px;
    }
}

.itemTitle {
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightSemiBold);
    color: var(--matterColor);
    font-size: 28px;
    line-height: 1.5em;

    @media (--viewportMedium) {
        font-size: 36px;
    }

    @media (--viewportLarge) {
        font-size: 42px;
    }
}

.itemLabel {
    color: var(--matterColor);
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;
    min-height: 100px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    @media (--viewportMedium) {
        min-height: 75px;
        font-size: 20px;
    }
}

.item {
    color: var(--matterColor);
    font-size: 16px;
    line-height: 1.5em;
    width: 100%;
    min-height: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (--viewportMedium) {
        min-height: 75px;
        font-size: 20px;
    }
}

.lastItemLabel {
    color: var(--matterColorDark);
    font-weight: var(--fontWeightBold);
    font-size: 22px;
    line-height: 1.5em;
    padding-bottom: 12px;
    min-height: 100px;
    margin: 24px 0;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: left;

    @media (--viewportMedium) {
        min-height: 100px;
        font-size: 32px;
    }
}

.lastItem {
    color: var(--matterColorDark);
    font-weight: var(--fontWeightBold);
    font-size: 22px;
    line-height: 1.5em;
    padding-bottom: 12px;
    min-height: 100px;
    margin: 24px 0;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (--viewportMedium) {
        min-height: 100px;
        font-size: 32px;
    }
}

.descriptionEarnings {
    width: 100%;

    font-size: 16px;

    margin: 48px auto 0;

    @media (--viewportMedium) {
        width: 50%;
        margin: 64px auto;
    }
}

.popups span {
    border-bottom: 2px dashed var(--matterColor);
    padding-bottom: 2px;
}

.popup {
    width: 400px;
}

.popupContent {
    font-size: 14px;
    line-height: 16px;
}