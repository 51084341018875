@import '../../../marketplace.css';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    @media (min-width: 1200px) {
        padding: 0 10%;
    }

    @media (min-width: 1681px) {
        padding: 0 25%;
    }
}

.preTitle {
    color: var(--matterColor);
    font-size: 0.8rem;
    margin: 0;
}

.title {
    color: var(--matterColor);
    margin: 0 0 1%;
    padding: 0 2.5%;
    width: 70%;
}

.content {
    color: var(--matterColor);
    padding: 0 16%;
    margin: 22px 0;
    line-height: 20px;
}

.buttonsWrapper {
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    width: 100%;
    margin: 5% 0 10%;

    @media (--viewportMedium) {
        grid-gap: 5em;
        grid-template-columns: repeat(2, minmax(25%, 1fr));
    }
}

.button {
    @apply --marketplaceButtonStyles;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;

    flex-basis: 100%;
    min-height: 45px;
    width: 100%;
    line-height: 0;
    padding: 0 15px;

    @media (--viewportMedium) {
        min-height: 55px;
    }
}

.buttonFindSpace {
    @apply --marketplaceButtonStyleColorDarkFill;
}

.buttonHostOnGofynder {
    @apply --marketplaceButtonStyleSecondaryClear;
}