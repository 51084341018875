@import '../../marketplace.css';

.root {
  flex-grow: 1;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 11px 24px 0 24px;
}

.form {
  flex-grow: 1;
}

.body {
  flex-grow: 1;
  width: 80%;
}

.title {
  margin-bottom: 19px;

  @media (--viewportLarge) {
    margin-bottom: 38px;
    padding: 1px 0 7px 0;
  }
}

.buttonsWrapper {
  display: flex;
  width: auto;
}

.submitButton {
  margin-right: 5px;
    margin-top: auto;
    flex-shrink: 0;
  
    @media (--viewportLarge) {
      display: inline-block;
      width: 241px;
      margin-top: 100px;
    }
  }

  .addAnotherListingButton {
    background-color: #2660A4;
    transition: var(--transitionStyleButton)
  }

  .addAnotherListingButton:hover {
    background-color: #083D77;
  }
