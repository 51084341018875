@import '../../../marketplace.css';
@import '../../../marketplaceFonts.css';
@import url('https://fonts.googleapis.com/css2?family=Sriracha&display=swap');

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--matterColor);
    text-align: left;
}

.title {
    @apply --marketplaceH1FontStyles;
    margin: 0;
    margin-right: auto;
}

.description{
    font-family: var(--secondaryFontFamily);
    font-size: 1.3em;
    font-weight: 600;
    margin-right: auto;
    width: 100%;
    
    media (--viewportMedium){
        width: 70%;
    }
}

.grid {
    margin-top: 10%;
    display: grid;
    grid-gap: 1.5em;
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

    @media (--viewportSmall) {
        margin-top: 8%;
        grid-template-columns: repeat(2, minmax(calc(45% - 1.5em), 1fr));
    }

    @media (min-width: 1200px) {
        margin-top: 5%;
        grid-template-columns: repeat(4, minmax(calc(25% - 4.5em), 1fr));
    }
}

.planContainer {
    background-color: var(--matterColorLight);
    color: var(--matterColor);
    font-size: 2em;
    border: 1px solid var(--matterColor);
    border-radius: var(--borderRadiusPanel);

    padding: 48px 32px;
}

.planNameContainer {
    font-family: var(--secondaryFontFamily);

    & h2 {
        font-family: inherit;
        font-size: 1em;
        line-height: 1em;
        margin: 0;
        padding: 0;
        
    }
}

.planDescription {
    font-family: var(--primaryFontFamily);
    font-size: 0.6em;
    line-height: 1em;

    margin: 24px 0 24px 0;
}

.featureContainer {   
    margin-top: 24px;
    margin-bottom: 24px;
}

.featureTitle {
    font-family: var(--secondaryFontFamily);
    font-size: 0.6em;
}

.featureDescription {
    font-family: var(--primaryFontFamily);
    font-size: 0.5em;
    line-height: 1em;
}

.mostPopularContainer{
    border: 1px solid var(--customColorHighlight);
    border-radius: var(--borderRadiusPanel);

    padding: 12px 32px;
}

.mostPopular{
    background-color: var(--customColorHighlight);
    color: var(--matterColorBright);
    font-family: 'Sriracha', cursive;
    font-size: 18px;
    position: relative;
    top: -31px;
    padding: 6px 12px;
    text-align: center;
    width: fit-content;
    margin: auto;
    border-radius: 12px;
}

.mostPopularUnselected{
    display: none;
    background-color: var(--marketplaceColor);
    color: var(--matterColorBright);
    font-size: 18px;
    position: absolute;
    top: -61px;
    padding: 6px 12px;
    text-align: center;
    width: fit-content;
    margin: auto;
}

.circle{
    background-color: var(--matterColorAnti);
    color: var(--matterColorLight);
    padding: 12px;
    width: 48px;
    height: 48px;
    text-align: center;
    border-radius: 100%;
    margin-bottom: 12px;
}