@import '../../../marketplace.css';

:root {
      
    --backgroundImage: {
        background:  url('./images/SD_HEADER_IMAGE.jpg');

        /* Add loading color for the div */
        /* background-color: var(--matterColor); */
        
        /* Cover the whole screen with the background image */
        background-size: auto 100%;
        
        /* Align the image within the container */
        background-position: right 0;
        background-repeat: no-repeat;

        @media (--viewportMedium){
            background-size: auto 90%;
        }
      }

      --button: {
        @apply --marketplaceButtonStyles;
    
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    
        font-size: 13px;
        font-weight: bold;
        line-height: 0;
        padding: 0;
    
        border-radius: 12px;
    
        min-height: 35px;
        height: 50px;
    
        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
    
        @media (--viewportMedium) {
          -ms-flex-preferred-size: calc(50% - 25px);
          -webkit-flex-basis: calc(50% - 25px);
          flex-basis: calc(50% - 25px);
          min-height: 45px;
          height: 60px;
        }
    
        @media (--viewportLarge) {
          font-size: 18px;
          min-height: 50px;
          height: 65px;
        }
      }
}

.root {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;

    @media (--viewportMedium) {
        @apply --backgroundImage;
        min-height: 600px;

    }
    /* @apply --backgroundImage;
    width: 100%;
    height: 100%; */
}

.container {
    display: flex;
    flex-direction: column;

    width: 100%;

    @media (--viewportMedium) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    @media (--viewportLarge){
        max-width: 58%;
    }

    @media (--viewportXLarge) {
        max-width: 65%;
    }

    @media (max-width: 1200px) {
        max-width: none;
        width: 100%;
    }
}

.content {
    position: relative;
    background-color: var(--marketplaceColorBgDark);

    flex-basis: 100%;
    padding: 24px;

    @media (--viewportMedium) {
        flex-basis: 60%;
    }

    @media (--viewportLarge) {
        flex-basis: 50%;
    }

    @media (min-width: 1200px) {
        flex-basis: 100%;
        padding: 96px;
        border-radius: 0 0 15px 0;
    }

    @media (min-width: 1645px) {
        padding: 120px;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    font-weight: 800;
    color: var(--matterColorBright);
    
    @media (--viewportMedium) {
        font-size: 44px;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    @media (--viewportLarge) {
        font-size: 52px;
        width: 90%;
    }

    @media (min-width: 1200px) {
        line-height: 80px;
        font-size: 72px;
    }
}

.description{
    color: var(--matterColorBright);
    font-size: 28px;
    line-height: 36px;
    width: 70%;
}

.image {
    @apply --backgroundImage;

    background-position: center 22%;

    flex-basis: 100%;
    background-size: 100% auto;
    padding-bottom: 250px;

    @media (--viewportMedium) {
        background-position: 70% center;
        background-size: auto 100%;
        flex-basis: 40%;
    }

    @media (--viewportLarge) {
        flex-basis: 50%;
    }

    @media (min-width: 1201px) {
        display: none;
    }
}

.formContainer {
    margin-top: 48px;
}

.link{
    @apply --button;
    background-color: var(--marketplaceSecondaryColor);
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 36px;

    @media (--viewportMedium) {
        width: 65%;
    }

    @media (--viewportXLarge) {
        width: 70%;
    }
}

.link:hover{
    color: var(--matterColorBright);
    background-color: var(--marketpalceSecondaryColorDark);
}