@import '../../marketplace.css';

.root {
}

.input {
  /* border-bottom-color: var(--attentionColor); */
  border-bottom-color: var(--marketplaceSecondaryColor);
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
  border-bottom-color: var(--marketplaceColorLight);
}

.inputError {
  border-bottom-color: var(--failColor);
}

.textarea {
}

.label {
  @apply --marketplaceInputLabelFontStyles;
}
