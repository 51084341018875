@import '../../../marketplace.css';

.root{
    background-color: var(--marketplaceSecondaryColor);
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    width: 70%;
    margin: 0 0 16px 0;
}

.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (--viewportMedium){
        flex-direction: row;
        align-items: flex-start;
        text-align: left;
    }
}

.infoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 0 16px 0;
    padding: 24px;

    @media (--viewportMedium){
        width: 50%;
        align-items: flex-start;
        text-align: left;
        padding: 96px 0 0 96px;
    }

    @media (--viewportLarge){
        padding: 96px 0 96px 340px;
    }
}

.info{
    width: 100%;
}

.infoTitle{
    @apply --marketplaceH1FontStyles;
    font-size: 2em;
    line-height: 1em;
    color: var(--matterColorBright);
    margin: 0 0 16px 0;
}

.infoText{
    color: var(--matterColorBright);
    margin: 0 0 16px 0;
}

.inforButtons{
    display: block;
    width: 80%;

    background: var(--matterColorBright);
    color: var(--matterColorDark);

    padding: 12px 24px;
    border-radius: 18px;

    text-align: center;
    font-weight: 600;

    margin: 12px auto 24px;

    @media(--viewportMedium){
        width: 250px;
        margin: 0;
        margin-bottom: 72px;
    }
}

.imageContainer{
    display: none;
    @media (--viewportMedium){
        display: block;
        width: 50%;
        align-items: flex-start;
        margin-top: auto;
    }
}

.image{

    @media (--viewportMedium){
        width: 100%;
    }

    @media (--viewportLarge){
        width: 75%;
    }
}