@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDatesWrapper {
  position: relative;
  /* margin: 0 24px; */
  /* max-width: 324px; */
  
  /* @media (--viewportMedium) {
    margin: 0;
  } */
}

.bookingDatesWrapper i {
  position: absolute;
  font-size: 14px;
  margin-right: 10px;
  margin-top: 10px;
  right: 3px;
  top: 3px;
  cursor: pointer;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  background-color: none;
  padding: 0;
  border-radius: 0;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
  color: var(--matterColor);
  
  @media (--viewportMedium) {
    background-color: var(--customLightGray);
    padding: 15px;
    border-radius: var(--borderRadiusPanel);
  }

}

.priceBreakdownContainer {
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);
  text-align: center;

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.timeSlotsError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 24px;
    width: 100%;
  }
}

.moreDaysButton {
}

.firstLine {
  font-weight: var(--fontWeightBold);
}

.moreDaysButton .secondLine {
  font-size: 12px;
  font-weight: var(--fontWeightLight);
}

.reserveButton {
  @apply --marketplaceButtonStyleReserve;
}

.reserveButton .firstLine {
  font-size: 22px;
}

.reserveButton .secondLine {
  font-weight: var(--fontWeightLight);
}

.minimumLengthError {
  margin-bottom: 24px;
  color: var(--failColor);
  padding: 0 24px;
}