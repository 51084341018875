@import '../../marketplace.css';

:root {
    --backgroundImage: {
        background:  url('./images/img_2207.jpg');

        background-size: auto 100%;
        
        background-position: left center;
        background-repeat: no-repeat;
      }
}

.root {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-end;

    @media (min-width: 1201px) {
        @apply --backgroundImage;
    }
}

.preTitle {
    color: var(--matterColor);
    font-weight: bolder;
    font-size: 0.8rem;
    text-align: center;
    margin: 0;
  }

.title {
    @apply --marketplaceH1FontStyles;

    color: var(--matterColor);

    text-align: center;
    
    @media (--viewportMedium) {
        font-size: 28px;
        margin: 0;
        padding: 0;
    }

    @media (--viewportLarge) {
        font-size: 36px;
    }

    @media (min-width: 1201px) {
        font-size: 40px;
    }
}

.description {
    @apply --marketplaceLandingPageSubtitleFontStyles;
}

.container {
    @media (max-width: 1200px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media (min-width: 1201px) {
        max-width: 55%;
        padding: 80px 0 80px 0;
    }
}

.image {
    @apply --backgroundImage;

    background-position: center center;

    @media (max-width: 658px) {
        flex-basis: 100%;
        background-size: 100% auto;
        padding-bottom: 200px;
    }

    @media (min-width: 659px) {
        flex-basis: 50%;
        height: auto;
    }

    @media (min-width: 1201px) {
        display: none;
    }
}

.content {
    position: relative;
    background-color: #F8F9FA;
    color: var(--matterColor);

    flex-basis: 100%;
    padding: 24px;

    @media (min-width: 659px) {
        flex-basis: 50%;
    }

    @media (min-width: 1200px) {
        padding: 56px 56px 56px 56px;
        border-radius: 15px 0 0 15px;
    }

    @media (min-width: 1645px) {
        padding: 96px 96px 96px 96px;
    }
}

.grid {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    padding-bottom: 32px;

    @media (--viewportMedium) {
        grid-template-columns: repeat(2, minmax(45%, 1fr));

        padding-top: 22px;
        padding-bottom: 32px;
    }

    @media (min-width: 1645px) {
        padding-top: 55px;
        padding-bottom: 65px;
    }
}

.bookingWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.bookingInfo {
    flex-basis: 80%;
}

.bookingTitle {
    @apply --marketplaceH2FontStyles;

    font-family: var(--secondaryFontFamily);

    @media (--viewportMedium) {
        font-size: 20px;
        margin: 0;
        padding: 0 0 4px 0;
    }

    @media (--viewportLarge) {
        font-size: 26px;
        padding: 0 0 8px 0;
    }

    @media (min-width: 1200px) {
        font-size: 28px;
    }
} 

.bookingDescription {
    line-height: 1.1em;
    font-size: 14px;

    margin: 0;
    padding: 0 15px 0 0;

    @media (--viewportLarge) {
        font-size: 16px;
    }

    @media (min-width: 1200px) {
        font-size: 16px;
    }
}

.more {
    @apply --marketplaceLearnMoreFontStyles;
    color: var(--marketplaceSecondaryColor);
}