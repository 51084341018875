@import '../../../marketplace.css';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

}

.preTitle {
    color: var(--matterColor);
    font-size: 0.8rem;
    margin: 0;
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin: 0 0 2%;
    width: 70%;
}

.grid {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
    width: 100%;

    @media (--viewportMedium) {
        grid-template-columns: repeat(3, minmax(30%, 1fr));
    }
}

.ourTeamContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    padding: 24px;

    @media (min-width: 1200px) {
        padding: 24px;
    }

    @media (min-width: 1681px) {
        padding: 96px;
    }
}

.ourTeamContainer:nth-child(1){
    border-bottom: 1px solid gray;

    @media (--viewportMedium) {
       /* border-right: 1px solid gray; */
        border-bottom: 0;
    }
}
.ourTeamContainer:nth-child(2){
    border-bottom: 1px solid gray;

    @media (--viewportMedium) {
       /* border-right: 1px solid gray; */
        border-bottom: 0;
    }
}

.bob{
    background-image: url('./images/Bob_Headshot.png');
    background-size: cover; 
    background-position: center center;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;

    @media (--viewportMedium){
        height: 400px;
    }
}

.terren{
    background-image: url('./images/Terren_Headshot.png');
    background-size: cover; 
    background-position: center center;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;

    @media (--viewportMedium){
        height: 400px;
    }
}

.penny{
    background-image: url('./images/Penny_Headshot.png');
    background-size: cover;  
    background-position: center center;
    background-repeat: no-repeat;
    height: 40vh;
    width: 100%;

    @media (--viewportMedium){
        height: 400px;
    }
}

.teamMembers{
    width: 100%;

    @media (--viewportMedium){
        
    }
}
.subtitle {
    font-weight: var(--fontWeightBlack);
    font-size: 1.5rem;
    margin: auto;
    margin-bottom: 7px;
    padding-top: 2.5%;

    color: var(--matterColor);

    @media (--viewportMedium) {
        width: 100%;
    }

    @media (--viewportLarge) {
        width: 80%;
    }
}

.description {
    font-size: 14px;
    line-height: 18px;
    margin: 2.5% auto;
    width: 80%;

    @media (--viewportMedium){
        font-size: 16px;
        line-height: 20px;
    }
}

.button {
    @apply --marketplaceButtonStyles;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;

    flex-basis: 100%;
    min-height: 45px;
    width: 100%;
    line-height: 0;
    padding: 0 15px;

    
}

.signUpButton {
    @apply --marketplaceButtonStyleSecondaryFill;
    margin: 2%;
    width: 100%;
    @media (--viewportMedium) {
        width: 15%;
    }
}