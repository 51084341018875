@import '../../../marketplace.css';

:root {

    --button: {
        @apply --marketplaceButtonStyles;

        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        font-size: 13px;
        font-weight: bold;
        line-height: 0;
        padding: 0;

        border-radius: var(--borderRadiusMedium);

        min-height: 35px;
        height: 35px;

        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;

        @media (--viewportMedium) {
            -ms-flex-preferred-size: calc(50% - 25px);
            -webkit-flex-basis: calc(50% - 25px);
            flex-basis: calc(50% - 25px);
            min-height: 45px;
            height: 45px;
        }

        @media (--viewportLarge) {
            font-size: 18px;
            min-height: 50px;
            height: 50px;
        }
    }
}

.root {
    display: flex;
    flex-direction: column-reverse;

    @media(--viewportLarge) {
        flex-direction: row;
    }
}

.headerContent {
    width: 100%;
    background-color: var(--matterColorLight);
    border-radius: 0 0 0 0;
    height: fit-content;
    display: flex;
    flex-direction: column;

    padding: 24px 24px 24px 24px;

    margin: auto 0;

    @media (--viewportSmall) {
        padding: 50px 50px 50px 50px;
    }

    @media (--viewportMedium) {
        border-radius: 0 0 50px 0;
        padding: 25px 50px 25px 50px;
    }

    @media (--viewportLarge) {
        width: 50%;
        padding: 45px 25px 256px 150px;
    }

    @media (min-width: 1681px) {
        padding: 25px 126px 264px 126px;
    }
}

.headerMainTitle {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin-bottom: 15px;

    font-size: 28px;
    line-height: 1.1em;

    @media (--viewportMedium) {
        font-size: 54px;
    }

    @media (--viewportLarge) {
        font-size: 64px;
    }

    @media (--viewportXLarge) {
        font-size: 72px;
    }
}

.headerSubTitle {
    font-family: var(--secondaryFontFamily);
    color: var(--matterColor);
    width: 100%;
    font-size: 24px;
    line-height: 1.5em;

    @media (--viewportMedium) {
        font-size: 28px;

        margin-bottom: 40px;
    }

    @media (--viewportLarge) {
        width: 80%;
        margin-bottom: 40px;
    }

    @media (--viewportXLarge) {
        font-size: 28px;

        padding-right: 15%;
    }
}

.signup {
    @apply --button;
    font-size: 16px;

    background-color: var(--marketplaceSecondaryColor);
    border-radius: 8px;
    margin-bottom: 18px;

    @media (--viewportMedium) {
        border-radius: 15px;
        margin-bottom: 0;
        width: 50%;
    }
}

.headerImage {
    width: 100%;

    @media (--viewportLarge) {
        display: flex;
        justify-content: flex-end;
        width: 50%;
    }
}

.image {
    width: 100%;
    max-height: 40vh;
    object-fit: cover;

    @media (--viewportLarge) {
        max-height: none;
        width: 95%;
        object-position: 50px -125px;
    }
}