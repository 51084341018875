@import '../../../marketplace.css';

:root {
    --backgroundImage: {
        background:  url('./images/host-header.png');

        /* Add loading color for the div */
        /* background-color: var(--matterColor); */
        
        /* Cover the whole screen with the background image */
        background-size: cover;
        
        /* Align the image within the container */
        background-position: left;
        background-repeat: no-repeat;
      }
}

.root {
    @apply --backgroundImage;

    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;

}

.container {
    display: flex;
    flex-direction: column;

    width: 100%;

    @media (--viewportMedium) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    @media (min-width: 1200px) {
        max-width: 65%;
        padding: 0 0 80px 0;
    }
}

.content {
    position: relative;

    flex-basis: 100%;
    padding: 24px;

    @media (--viewportMedium) {
        padding: 56px;
        border-radius: 0 0 15px 0;
    }

    @media (--viewportLarge) {
        padding: 96px 96px 96px 150px;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColorBright);
    margin: 0;

    @media (--viewportMedium) {
        width: 60%;
    }

    @media (--viewportLarge) {
        font-size: 3.2rem;
        letter-spacing: 0.15rem;
        width: 70%;
    }
}

.description{
    color: var(--marketplaceColorGrey);
    width: 85%;

    @media (--viewportMedium) {
        width: 55%;
    }

    @media (--viewportLarge) {
        width: 60%;
    }
}

.buttonWrapper{
    display: flex;
    flex-direction: column;
    justify-content: left;
    margin-top: 20px;
}

.listYourSpace{
    @apply --marketplaceButtonStyleSecondaryFill;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (--viewportMedium) {
        width: 250px;
    }
}

.faqButton{
    @apply --marketplaceButtonStylesSecondary;
    margin-top: 20px;
    margin-bottom: 20px;

    @media (--viewportMedium) {
        width: 250px;
    }
}