@import '../../../marketplace.css';

.root{
    text-align: center;
}

.title {
    @apply --marketplaceH1FontStyles;
    margin: 0 0 16px 0;
    color: var(--matterColor);
}

.description{
    font-size: 1.2em;
}

.link{
    @apply --marketplaceButtonStyleSecondaryFill;
    margin: 0 auto;
    width: 100%;

    @media (--viewportMedium){
        width: 60%;
    }

    @media (--viewportLarge){
        width: 10%;
    }
}