@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);
  text-align: center;
  margin: 0 auto;
  width: 60%;

  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.preTitle {
  color: var(--matterColor);
  font-weight: bolder;
  font-size: 0.8rem;
  text-align: center;
  margin: 0;
}

.grid {
  display: -webkit-grid;
  display: grid;
  -webkit-grid-gap: 3em;
  grid-gap: 3em;
  -webkit-grid-template-columns: repeat(auto-fit, 100%);
  grid-template-columns: repeat(auto-fit, 100%);
  margin-top: 65px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, minmax(40%, 1fr));
    -webkit-grid-template-columns: repeat(2, minmax(40%, 1fr));
    margin-top: 85px;
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, minmax(30%, 1fr));
    -webkit-grid-template-columns: repeat(3, minmax(30%, 1fr));
    margin-top: 85px;
  }
}

.featureWrapper {
  display:-webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: unwrap;
  flex-wrap: unwrap;

  text-align: center;
}

.featureWrapperNone {
  display: none;

  @media (--viewportLarge) {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: unwrap;
    flex-wrap: unwrap;

    text-align: center;
  }
}

.imageWrapper {
  -webkit-flex-basis: calc(12.5% - 24px);
  flex-basis: calc(12.5% - 24px);
  -webkit-filter: invert(27%) sepia(45%) saturate(1896%) hue-rotate(188deg) brightness(100%) contrast(83%);;
  filter: invert(27%) sepia(45%) saturate(1896%) hue-rotate(188deg) brightness(100%) contrast(83%);
  margin: 5% 0;
  & img {
    width: 15%;
  }
}

.featureInfo {
  color: var(--matterColor);
  -webkit-flex-basis: 80%;
  flex-basis: 80%;
}

.featureTitle {
  font-family: var(--secondaryFontFamily);
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;

  margin: 25px 0 10px 0;
  padding: 0;
}

.featureDescription {
  line-height: 1.1em;

  margin: 0;
  padding: 0 10px;
}

.footer {
  margin: 85px 0 32px 0;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
}

.getStarted {
  

  @apply --marketplaceButtonStyles;

  @apply --marketplaceButtonStyleSecondaryFill;

  display: flex;
  display:-webkit-flex;
  justify-content: center;
  align-items: center;
  justify-self: center;

  min-height: 45px;
  width: 100%;
  line-height: 0;
  padding: 0 15px;

  @media (--viewportMedium) {
      min-height: 55px;
      width: 25%;
  }

  &:hover {
    color: var(--matterColorLight);
  }
}