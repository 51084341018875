/* Fonts */
/* These are imported into marketplace.css */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@400;500;600;700&display=swap');

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --fontWeightLight: 300;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;
  --fontWeightExtraBold: 800;
  --fontWeightBlack: 900;

  --primaryFontFamily: 'Raleway', sans-serif;
  --secondaryFontFamily: 'Archivo Narrow', sans-serif;

  /* ================ Default font ================ */

  --marketplaceDefaultFontStyles: {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: var(--primaryFontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* No margins for default font */

    @media (--viewportMedium) {
      font-size: 18px;
      line-height: 24px;
    }
  }

  --marketplaceSmallFontStyles: {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
  }

  /* ================ Body font ================ */

  --marketplaceBodyFontStyles: {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: 'Raleway', 'sans-serif';
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-size: 18px;
      line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  /* ================ Hero title ================ */

  --marketplaceHeroTitleFontStyles: {
    font-weight: var(--fontWeightBold);
    font-size: 42px;
    line-height: 42px;
    letter-spacing: -1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 25px;
    margin-bottom: 20px;

    @media (--viewportMedium) {
      font-size: 72px;
      line-height: 72px;
      letter-spacing: -1.5px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 25px;
      margin-bottom: 31px;
    }

    @media (min-width: 1681px) {
      font-size: 90px;
      line-height: 96px;
      letter-spacing: -2px;
    }
  }

  --marketplaceLandingPageSubtitleFontStyles {
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightSemiBold);
    font-size: 25px;

    margin: 0;
  }

  /* ================ Modal title ================ */

  --marketplaceModalTitleStyles {
    font-weight: var(--fontWeightSemiBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -0.5px;
    margin: 0;
    font-weight: var(--fontWeightBold);

    @media (--viewportMedium) {
      font-weight: var(--fontWeightSemiBold);
      line-height: 40px;
      letter-spacing: -0.9px;
      margin: 0;
    }
  }

  /* ================ H1, H2, H3, H4, H5 & H6 ================ */

  --marketplaceH1FontStyles: {
    font-family: var(--primaryFontFamily);
    font-weight: var(--fontWeightExtraBold);
    font-size: 30px;
    line-height: 36px;
    letter-spacing: -1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 18px;
    margin-bottom: 18px;

    @media (--viewportMedium) {
      font-size: 48px;
      /* font-weight: var(--fontWeightSemiBold); */
      line-height: 56px;
      letter-spacing: -2px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }

  --marketplaceH2FontStyles: {
    font-weight: var(--fontWeightSemiBold);
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.3px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 21px;
    margin-bottom: 17px;

    @media (--viewportMedium) {
      line-height: 32px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 21px;
      margin-bottom: 19px;
    }
  }

  --marketplaceH3FontStyles: {
    font-family: 'Raleway', 'sans-serif';
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.2px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 16px;
    margin-bottom: 14px;

    @media (--viewportMedium) {
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 16px;
      margin-bottom: 16px;
    }
  }

  --marketplaceH4FontStyles: {
    /* font-family: 'Archivo Narrow', sans-serif; */
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 12px;
    margin-bottom: 12px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 17px;
      margin-bottom: 15px;
    }
  }

  --marketplaceH5FontStyles: {
    /* font-weight: var(--fontWeightRegular); */
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 24px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 14px;
    }
  }

  --marketplaceH6FontStyles: {
    font-weight: var(--fontWeightBold);
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 10px;
    margin-bottom: 8px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10px;
      margin-bottom: 6px;
    }
  }

  /* ================ Other fonts ================ */

  --marketplaceTinyFontStyles: {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    /* font-family: 'Raleway', 'sans-serif'; */
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;
    /* margin-top + n * line-height + margin-bottom => x * 6px */
    margin-top: 9.5px;
    margin-bottom: 8.5px;

    @media (--viewportMedium) {
      line-height: 16px;
      /* margin-top + n * line-height + margin-bottom => x * 8px */
      margin-top: 10.5px;
      margin-bottom: 13.5px;
    }
  }

  --marketplaceMessageFontStyles {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: 'Raleway', 'sans-serif';
    font-weight: var(--fontWeightRegular);
    font-size: 16px;
    line-height: 24px;
  }

  --marketplaceMessageDateFontStyles {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: 'Raleway', 'sans-serif';
    font-weight: var(--fontWeightRegular);
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.1px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 13px;
      line-height: 24px;
    }
  }

  --marketplaceTxTransitionFontStyles {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: 'Raleway', 'sans-serif';
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-size: 20px;
      line-height: 32px;
    }
  }

  --marketplaceSearchFilterLabelFontStyles {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: 'Raleway', 'sans-serif';
    font-weight: var(--fontWeightSemiBold);
    font-size: 13px;
    line-height: 18px;

    @media (--viewportMedium) {
      font-weight: var(--fontWeightMedium);
      font-size: 16px;
      line-height: 20px;
    }
  }

  --marketplaceSearchFilterSublabelFontStyles {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: var(--primaryFontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 18px;
  }

  --marketplaceListingAttributeFontStyles {
    /* font-family: 'sofiapro', Helvetica, Arial, sans-serif; */
    font-family: 'Raleway', 'sans-serif';
    font-weight: var(--fontWeightMedium);
    font-size: 18px;
    line-height: 32px;
  }

  --marketplaceInputLabelFontStyles {
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightSemiBold);
  }

  --marketplaceEditListingPanelTitleFontStyles {
    @apply --marketplaceH1FontStyles;
    font-weight: var(--fontWeightBlack);
  }

  --marketplaceEditListingAttributeTitleFontStyles {
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 20px;
    margin-bottom: 5px;
  }

  --marketplaceEditListingAttributeValueFontStyles {
    font-family: var(--primaryFontFamily);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
  }


  --marketplaceEditListingCheckboxItemFontStyles {
    font-family: var(--primaryFontFamily);
    font-weight: var(--fontWeightSemiBold);
    font-size: 16px;
  }

  --marketplaceEditListingSectionDescriptionFontStyles {
    @apply --marketplaceDefaultFontStyles;
    color: var(--matterColor);
    line-height: 1.25rem;
    font-size: 0.90rem;

    margin: 7px 0 22px 0;

    & p {
      line-height: inherit;
      font-size: inherit;
    }
  }

  --marketplaceLearnMoreFontStyles {
      color: var(--matterColorDark);
      text-decoration: underline;

      & a {
        color: inherit;
        text-decoration: inherit;
      }
  }

  /* ================ Tabbed navigation font styles ================ */

  --marketplaceTabNavFontStyles {
    /* font-weight: var(--fontWeightMedium); */
    font-weight: var(--fontWeightBold);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;

    @media (--viewportLarge) {
      /* font-weight: var(--fontWeightSemiBold); */
      font-size: 24px;
      line-height: 32px;
    }
  }

  --marketplaceTabNavHorizontalFontStyles {
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
  }
}
