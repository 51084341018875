@import '../../marketplace.css';

.fieldGroup {
    display: flex;
}

.fieldGroup span {
    cursor: pointer;
}

.field {
    margin-bottom: 16px;
}