@import '../../marketplace.css';

:root {
    --backgroundImage: {
        background-size: cover;
        
        background-position: center;
        background-repeat: no-repeat;
    }

    --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
    @apply --backgroundImage;

    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;

    margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

    @media (--viewportMedium) {
        max-width: 100%;
        padding: 24px 24px 0 24px;
        margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
    }

    @media (--viewportMedium) {
        max-width: 1760px;
        padding: 24px 24px 0 24px;
        margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
    }

    @media (--viewportLarge) {
        min-height: 400px;
        height: 65vh; 
        padding: 0 150px 0 150px;
        margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
    }
}

.aboutPage{

    @apply --backgroundImage;

    position: relative;

    display: flex;
    flex-direction: column;

    width: 100%;
    /* min-height: 65vh; */
    padding: 5% 10%;

    @media (--viewportMedium){
        min-height: 400px;
        height: 65vh; 
    }

    @media (min-width: 550px) {
        min-height: 65vh;
    }
    text-align: center;

}

.aboutTitle {
    @apply --marketplaceH1FontStyles;
    font-size: 3.5rem;
    line-height: 0.9em;
    color: var(--matterColorLight);
    width: 100%;
    
    @media (--viewportMedium){
        font-size: 4rem;
        margin-top: 17vh !important;  
    }

    @media (min-width: 550px) {
        font-size: 4rem;
        margin-top: 17vh !important;  
    }

    @media (--viewportLarge) {
        font-size: 7rem;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    font-size: 3.5rem;
    line-height: 0.9em;
    color: var(--matterColorLight);
    width: 100%;
    
    @media (--viewportMedium){
        width: 80%;
        font-size: 4rem;
    }

    @media (--viewPortSmall) {
        font-size: 4rem;
    }
}

.reference {
    color: var(--matterColorLight);
}

.referenceTitle {
    display: block;
    font-family: var(--secondaryFontFamily);
    font-size: 1.8em;
    line-height: 1.5rem;
    width: 100%;

    @media (--viewportMedium){
        width: 50%;
    }
}