@import '../../marketplace.css';

.label {
    @apply --marketplaceInputLabelFontStyles;
}

.input{
    @apply --marketplaceH4FontStyles;
    font-family: var(--secondaryFontFamily);
}

.help {
    /* margin-top: 0;
    color: var(--matterColorAnti); */
    @apply --marketplaceEditListingSectionDescriptionFontStyles;
    margin-top: 0;
}