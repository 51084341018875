@import '../../marketplace.css';

:root {
    --rootField: {
        border: 1px solid var(--matterColor);
        padding: 12px;
        border-radius: 7px;
    }
    --field: {
        margin-bottom: 22px;
    }
}

.rootField {
    @apply --rootField;

    &::placeholder {
        font-weight: var(--fontWeightBold);
    }
}

.fieldName {
    @apply --field;
    width: 75%;

    @media (--viewportSmall) {
        width: 50%;
    }
}

.fieldEmail {
    @apply --field;
    width: 75%;

    @media (--viewportSmall) {
        width: 50%;
    }
}

.fieldMessage {
    @apply --field;
    width: 95%;
}

.rootField {
    @apply --rootField;
}

.notarobotText {
    font-family: var(--secondaryFontFamily) !important;
    font-size: 18px;
}

.button {
    margin-top: 22px;
    width: 240px;
}

.error {
    color: var(--failColor);
}

.success {
    color: var(--successColor);
}