@import '../../marketplace.css';

.heading {
  margin: 5px 0 18px 0;

  @media (--viewportMedium) {
    margin: 8px 0 24px 0;
  }
}

.content {
  @media (--viewportLarge) {
    max-width: 563px;
  }
}

.sideBar{
  font-size: 2em;
}

