@import '../../marketplace.css';

:root {
    --rootField: {
        border: 1px solid var(--matterColor) !important;
        padding: 12px;
        border-radius: 7px;

        font-weight: var(--fontWeightBold);
        font-size: 16px;
    }
    --field: {
        margin-bottom: 22px;
    }
}

.rootField {
    @apply --rootField;

    &::placeholder {
        font-weight: inherit;
    }
}

.fieldAddress {
    @apply --field;
    width: 80%;
}

.icon {
    display: none !important;
}

.button {
    @apply --marketplaceButtonStyleSecondaryFill;

    line-height: 0;
    padding: 0;

    margin-top: 22px;
    width: 50%;

    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 56px;

    border-radius: 7px;

    &:focus,
    &:hover {
        background-color: var(--marketplaceSecondaryColor) !important;
    }
    &:disabled {
        background-color: var(--marketplaceSecondaryColor);
    }
}

.error {
    color: var(--failColor);
}

.success {
    color: var(--successColor);
}