@import "../../marketplace.css";

:root {
  --containers: {
      display: flex;
      justify-content: space-between;
      width: 100%;
  };

  --column: {
    flex-basis: calc(25% - 18px);
    margin: 0;
    marging-right: 18px;
    padding-bottom: 1px;
  }
}

.noteToDelete {
  margin-bottom: 12px;
}

.submitButton {
    margin-top: auto;
    margin-bottom: 24px;
    flex-shrink: 0;
  
    @media (--viewportLarge) {
      display: inline-block;
      width: 241px;
      margin-top: 100px;
    }
  }

  .labels {
    @apply --containers;
    padding-bottom: 6px;
  }

  .selectors {
    @apply --containers;
    padding-bottom: 2px;
  }
  
  .descriptions {
    @apply --containers;
    padding-bottom: 12px;
  }

  .prices {
    @apply --containers;
    padding-bottom: 12px;
  }

  .lengths {
    @apply --containers;
    padding-bottom: 12px;
  }

  .label {
    @apply --column;
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightMedium);
  }

  .selector {
    @apply --column;
  }

  .description {
    @apply --column;

    & p {
      font-size: 16px;
      line-height: 1.1em;
    }

    & > span {
      font-family: var(--secondaryFontFamily);
    }
  }

  .price {
    @apply --column;
  }

  .length {
    @apply --column;
  }

  .column3 {
    flex-basis: calc(33.33% - 18px);
  }

  .input {
    & input {
      font-weight: var(--fontWeightSemiBold);
      font-size: 0.9rem;
    }
    & select {
      font-weight: var(--fontWeightSemiBold);
      font-size: 0.9rem;
    }
  }

  .option{
    font-weight: var(--fontWeightSemiBold);
    font-size: 0.9rem;
  }

  .inputHelp {
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightRegular);
    font-size: 1.1em;
  }

  .error{
    background-color: var(--failColor);
    color: var(--successColorLight);
  }