@import '../../marketplace.css';
@import '../../marketplaceFonts.css';

.content{
    margin: 24px;
    
    @media(--viewportMedium){
        margin: 24px 175px;
    }

    @media(--viewportLarge){
        margin: 24px 320px;
    }
}

.title{
    @apply --marketplaceH1FontStyles;
}