@import '../../marketplace.css';

.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    background: url("./images/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px;

    @media(--viewportLargeWithPaddings) {
        background-size: 100%;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColorBright);
    text-align: center;
     
    @media (--viewportMedium) {
        font-size: 32px;
        margin: 0;
        padding: 0;
    }

    @media (--viewportLarge) {
        font-size: 40px;
        margin-bottom: 12px;
    }

    @media (min-width: 1201px) {
        font-size: 48px;
    }
}

.container{
    padding: 24px;
    margin: auto 0;

    @media (--viewportMedium) {
        padding: 32px;
    }

    @media (--viewportLargeWithPaddings) {
        padding: 48px 0;
    }
}

.info{
    color: var(--matterColorBright);
    font-size: 1.2em;
    margin-bottom: 0;
}

.startHosting {
    @apply --marketplaceButtonStyles;

    width: 240px;
    border-radius: var(--borderRadiusMedium);

    background-color: var(--marketplaceSecondaryColor);

    margin: 32px auto;

    &:hover {
        background-color: #cf676a;
        color: var(--matterColorLight);
    }

    @media(--viewportLargeWithPaddings) {
        margin: 64px auto;

    }
}