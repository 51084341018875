@import '../../marketplace.css';

.content {
  @media (--viewportMedium) {
    margin: 32px auto 0 auto;
    width: 100%;
  }

  @media (--viewportLarge) {
    margin: 0;
  }
}

.desktopTopbar,
.mobileTopbar {
  box-shadow: none;
}

.title {
  hyphens: auto;
  margin: 8px 0;
}

.description{
  font-size: 16px;
  font-weight: 700;
  color: var(--matterColor);
  width: 100%;
  margin: 0;
}

.subMessage{
  font-size: 14px;
  font-weight: 700;
  color: var(--matterColorAnti);
  width: 100%;
  margin: 0;

  & a{
    color: var(--matterColorAnti);
    text-decoration: underline;
  }
}

.image{
  width: 100px;
  margin: auto 24px;
}

.verifiedIcon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;

  & img {
    width: 100%;
    cursor: pointer;
    margin-bottom: 12px;
  }
}

.helpContainer{
  width: 75vw;
  text-align: center;

  @media(--viewportMedium){
    width: 400px;
  }
}

.helpTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1em;

  padding: 0;
  margin-bottom: 2px;
  margin-top: 12px;
}

.helpText {
  /* @apply --marketplaceTinyFontStyles; */
  font-size: 16px;
  line-height: 1.1em;
  letter-spacing: 0.5px;

  margin: 0 0 5px 0;
}

.helpTextMarked {
  /* @apply --marketplaceTinyFontStyles; */
  font-size: 14px;
  line-height: 1.1em;
  letter-spacing: 0.5px;

  margin: 0 0 5px 0;
}

.form{
  @media (--viewportMedium) {
    max-width: 564px;
  }
}