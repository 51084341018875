@import '../../../marketplace.css';

.root{
    background: url('./images/host-image.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 24px;

    @media(--viewportLargeWithPaddings) {
        background-size: 100%;
    }
}

.container{
    background-color: var(--matterColorDarkTransparent);
    padding: 24px;
    margin: auto 0;
    border-radius: 24px;

    text-align: center;

    @media (--viewportMedium) {
        padding: 32px;
    }

    @media (--viewportLargeWithPaddings) {
        padding: 48px 0;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    margin:  0 16px 0;
    color: var(--matterColorBright);

    @media (--viewportLarge){
        margin-bottom: 24px;
    }
}

.description{
    font-size: 1.2em;
    color: var(--matterColorBright);
}

.link{
    @apply --marketplaceButtonStyleSecondaryFill;
    margin: 0 auto;
    width: 100%;

    @media (--viewportMedium){
        width: 60%;
    }

    @media (--viewportLarge){
        width: 20%;
        margin-bottom: 24px;
    }
}