@import '../../../marketplace.css';

:root {
    --backgroundImage: {
        background:  url('./images/iStock-962529334.jpg');

        background-size: auto 100%;
        
        background-position: left center;
        background-repeat: no-repeat;
      }
}

.root {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    margin-bottom: 5%;

    @media (min-width: 1201px) {
        @apply --backgroundImage;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColorBright);
    @media (--viewportMedium) {
        font-size: 32px;
        margin: 0;
        padding: 0;
    }

    @media (--viewportLarge) {
        font-size: 40px;
    }

    @media (min-width: 1201px) {
        font-size: 48px;
    }
}

.description {
    @apply --marketplaceLandingPageSubtitleFontStyles;
    font-family: var(--primaryFontFamily);
    color: var(--matterColorBright);
    margin-top: 24px;
    margin-bottom: 24px;
}

.container {
    width: 100%;

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media (min-width: 1201px) {
        max-width: 55%;
        padding: 80px 0 80px 0;
    }
}

.image {
    @apply --backgroundImage;

    background-position: center center;

    @media (max-width: 658px) {
        flex-basis: 100%;
        background-size: 100% auto;
        padding-bottom: 200px;
    }

    @media (min-width: 659px) {
        flex-basis: 50%;
        height: auto;
    }

    @media (min-width: 1201px) {
        display: none;
    }
}

.content {
    position: relative;
    background-color: var(--marketplaceColorBgDark);

    flex-basis: 100%;
    padding: 24px;

    @media (min-width: 659px) {
        flex-basis: 50%;
        min-height: 400px;
    }

    @media (min-width: 1200px) {
        padding: 56px 220px 56px 56px;
        border-radius: 15px 0 0 15px;
    }

    @media (min-width: 1645px) {
        padding: 96px 220px 96px 96px;
    }
}

.grid {
    display: grid;
    grid-gap: 1fr;

    margin: 22px 0;

    & p {
        line-height: 1.1em;
    }
}

.joinGofynder {
    @apply --marketplaceButtonStyles;
    font-weight: var(--fontWeightBold);
    font-size: 16px;
    

    background-color: var(--marketplaceSecondaryColor);
    width: 200px;
    min-height: 56px;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: var(--marketpalceSecondaryColorDark);
        color: var(--matterColorLight);
    }
}