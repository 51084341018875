@import '../../marketplace.css';

.logoMobile {

  width: 150px;
  
  & path {
    fill: var(--marketplaceColor);
  }
}
