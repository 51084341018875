@import '../../marketplace.css';
@import '../../marketplaceFonts.css';

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Display format */
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.subtitle{
  @apply --marketplaceH2FontStyles;
  font-size: 28px;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
}

.introduction {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 7px 0;
  }
}

.introductionLink {
  color: var(--matterColor);
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportLarge) {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0 0 7px 0;
  }
}

.introductionLink:hover {
  color: var(--matterColorAnti);
}

.content {
  margin-top: 48px;
  margin-bottom: 24px;
}

.rules{
  margin-top: 0;
  margin-bottom: 0;
}

.error {
  @apply --marketplaceModalErrorStyles;
  margin-top: 0;
  margin-bottom: 12px;
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 24px;
  }
}