@import '../../marketplace.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.panelDetails {
  margin-bottom: 24px;
  background-color: var(--customLightGray);
  padding: 15px;
  border-radius: var(--borderRadiusPanel);
}

.periodDetails {
  display: flex;
  flex-direction: column;
}

.periodDetailsCenter {
  justify-content: center;
  align-items: center;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-top: 6px;
    padding-bottom: 10px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemDay {
  @apply --marketplaceH4FontStyles;
  margin: 10px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  color: var(--marketplaceColorLight);
  margin: 0;
  padding-top: 6px;
  padding-bottom: 6px;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }
}

.dateLabel {
  color: var(--matterColor);
  flex: 1;
  font-size: 18px;
  line-height: 18px;
  padding: 6px 0;
}

.itemLabel {
  position: relative;
  color: var(--matterColor);
  flex: 1;
  font-size: 17px;
}

.itemLabel i{
  position: absolute;
  font-size: 14px;
  margin-left: 3px;
  top: -3px;
  cursor: pointer;
}

.timeLabel {
  color: var(--matterColor);
  font-weight: normal;
  font-size: 14px;
}

.itemValue {
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  margin: 0 0 0 10px;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--matterColorNegative);

  @media (--viewportMedium) {
    margin: 7px 0 0px 0;
  }
}

.totalLabel {
  font-weight: var(--fontWeightBold);
  font-size: 18px;
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0 0 0 10px;
  padding-top: 0px;
  font-size: 18px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.payPerMonth {
  font-style: italic;
}

.bookingSummaryTitle {
  display: inline-block;
  position: relative;
  color: var(--matterColor);
  margin-bottom: 5px;
}

.bookingSummaryTitle i {
  position: absolute;
  font-size: 14px;
  margin-left: 3px;
  top: -3px;
  cursor: pointer;
}

.lineItemMonth {
  margin: 0 0 32px 0;
}

.monthDetailSubtitle {
  margin-top: 15px;
}

.spaceTimesDescription {
  margin-bottom: 10px;
}

.timeSlotsWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.lineTimeSlots {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.timeSlotLabel {
  margin-right: 10px;
}

.classTimeSlotLabel {
  @apply --marketplaceTinyFontStyles;
  padding-left: 14px;
  margin: 0;
  align-self: center;
}

.bookingOptionWrapper {
  margin-bottom: 24px;
}

.bookingOptionTitle {
  font-weight: var(--fontWeightBold);
  border: 2px solid var(--marketplaceSecondaryColor);
  padding: 7px 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.bookingOptionDescription {
  @apply --marketplaceBodyFontStyles;
  font-weight: var(--fontWeightSemiBold);
  color: var(--matterColorAnti);
  font-size: 16px;
  line-height: 18px;
}

.datePickerDisplay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 32px;
}

.datePickerTimeItem,
.datePickerDateItem {
  @apply --marketplaceDefaultFontStyles;
  margin-bottom: 10px;
}

.datePickerDateItem {
  width: 100%;
}

.datePickerTimesWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.datePickerTimeItem {
  width:50%;
}

.datePickerTimeItem:first-child {
  width: calc(50% - 20px);
  margin-right: 20px;
}

.datePickerLabel {
  font-weight: var(--fontWeightSemiBold);
}

.datePickerValue {
}

.datePickerSeparator {
  width: 100%;
  height: 2px;
  background-color: var(--marketplaceColorLight);
  margin-top: 10px;
}

.bookingPeriodCheckout {
  margin-bottom: 24px;
}

.bookingTimeSlots {
  margin-bottom: 24px;
}

.lineClassSlotDate {

}

.rootClassSlots {
  margin-top: 5px;
}

.bookingTimeSlotsDetails {
  margin-top: 5px;
}

.lineItemDateTimeDay {
  margin-bottom: 5px;
}