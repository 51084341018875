@import '../../../marketplace.css';

.root {
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
        padding: 0 15%;
    }

    @media (min-width: 1681px) {
        /* padding: 0 25%; */
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    width: 70%;
    color: var(--matterColor);
    text-align: center;
    margin: 0;
}

.grid {
    display: grid;
    grid-gap: 76px;
    grid-template-columns: repeat(1, minmax(100%, 1fr));

    margin: 96px 0 32px 0;
}

.itemWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.iconWrapper {
    flex-basis: 40px;
    margin-right: 15px;
    color: var(--matterColor);
    line-height: 1.5em;

    & i {
        font-size: 40px
    }
}

.infoWrapper {
    flex: 1;

    & h2 {
        color: var(--matterColor);
        font-family: var(--secondaryFontFamily);
        font-size: 1.5em;
        line-height: 1em;

        margin-bottom: 22px;
    }
}