@import '../../marketplace.css';

/* Booking form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  @apply --marketplaceModalInMobileBaseStyles;

  height: 100vh;

  @media (--viewportMedium) {
    flex-basis: 576px;
    height: 100%;
    padding: var(--modalPaddingMedium);
    background-color: var(--matterColorLight);
    margin-top: 12.5vh;
    margin-bottom: 12.5vh;
  }

  @media (--viewportLarge) {
    padding: 0;
    background-color: transparent;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.modalHeading {
  margin-top: 89px;
  margin-bottom: 36px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.title {
  /* Font */
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);

  /* Layout */
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.author {
  width: 100%;
  @apply --marketplaceH4FontStyles;
  margin-top: 7px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    color: var(--matterColor);
  }
}

.bookingHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin-top: -2px;
    margin-bottom: 33px;
    min-width: 312px;
  }
}

.bookingTitle {
  /* Font */
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightBold);
  font-size: 26px;

  margin-top: 0;
  margin-bottom: 24px;
}

.bookingHelp {
  display: none;
  font-family: var(--secondaryFontFamily);
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  color: var(--matterColor);
  width: max-content;

  @media (--viewportMedium) {
    display: block;
    margin-top: 0;
    margin-bottom: 24px;
  }
}

.bookingForm {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: 0 0 84px 0;

  @media (--viewportMedium) {
    margin: 0;
    min-height: 400px;
  }

  @media (--viewportLarge) {
    min-width: 312px;
    min-height: auto;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  /* padding: 0 24px 24px 24px; */
  padding: 0 24px 12px 24px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: var(--matterColorLight);
  /* margin-bottom: 24px; */
  margin-bottom: 4px;

  @media (--viewportMedium) {
    padding: 0;
    width: 100%;
    position: static;
    bottom: unset;
    background-color: transparent;
  }
}

.openBookingForm {
  /* Ensure that mobile button is over Footer too */
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 18px 24px 18px 16px;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowTop);
  display: flex;

  /* Contain repainting to this component only */
  /* 3D painting container helps scrolling */
  transform: translate3d(0, 0, 0);

  @media (--viewportMedium) {
    padding: 18px 60px 18px 60px;
  }

  @media (--viewportLarge) {
    display: none;
  }
}

.priceContainer {
  /* Layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin-right: 22px;
  padding: 5px 12px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.bookButton {
  @apply --marketplaceButtonStylesPrimary;
  background-color: var(--successColorSecondary);

  /* Clear padding that is set for link elements looking like buttons */
  padding: 0;
}

.closedListingButton {
  border-left: 1px solid var(--matterColorNegative);
  width: 100%;
  padding: 15px 24px 15px 24px;
  text-align: center;
}

.bookingOptionsRoot {
  display: flex;
  margin-bottom: 36px;
  line-height: 0.55rem;
  flex-wrap: wrap;

  @media (--viewportMedium) {
    margin-top: 14px;
  }
}

.bookingOptionsMenuModal {
  margin-top: 14px;
}

.bookingOptionRightColumn {
  display: flex;
  flex-direction: column;
}

.bookingOptionTopLine {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* justify-content: flex-start; */
}

.topLineFirstColumn {
  display: flex;
  flex-direction: row;
}

.bottomLine {
  display: flex;
  width: 100%;
  margin-left: 35px;
}

.radioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioInput {
  /* @apply --marketplaceRadioInputStyles;
  margin-right: 15px; */
  display: none;
  /* position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; */
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

.radioContainer:hover input + .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked + .checkmark {
  background-color: var(--marketplaceColorLight);
}

/* Show the indicator (dot/circle) when checked */
.radioContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioContainer .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.bookingOptionlabel {
  color: var(--matterColor);
  font-weight: var(--fontWeightMedium);

  padding: 0;
  margin: 0;
  position: relative;
}

.bookingOptionlabel i {
  position: absolute;
  font-size: 14px;
  margin-left: 3px;
  top: -3px;
  cursor: pointer;
}

.bookingOptionMinimumLength {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  margin: 0;
}

.bookingOptionprice {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColor);

  margin-top: 0;
  margin-bottom: 0px;
  /* margin-left: 30px; */

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.missingBookingOptions {
  @apply --marketplaceModalErrorStyles;
}

.error {
  @apply --marketplaceModalErrorStyles;
  margin-top: 22px !important;
}

.sticky {
  position: none;
  top: 0;

  @media (--viewportLarge) {
    position: sticky;
    top: 100px;
  }
}

.stripeContainer{
  display: flex;
}

.stripeImage{
  max-height: 32px;
  padding-left: 10px;
}