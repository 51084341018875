@import '../../marketplace.css';

.root {
  background-color: #DA6C6F;
  opacity: 0.5;

  color: var(--matterColorBright);

  padding-top: 100px;
  padding-bottom: 100px;
}

.content {
  padding: 0 50px  0 50px;

  @media (--viewportMedium) {
    padding: 0 150px 0 150px;
  }

  @media (min-width: 1200px) {
    padding: 0 27% 0 27%;
  }
}

.testimonialText {
  @apply --marketplaceH1FontStyles;
  position: relative;
  font-weight: var(--fontWeightBold);
  font-size: 22px;
  text-align: left;
  margin: 0;
  padding: 0;
  line-height: 1.1em;

  @media (--viewportSmall) {
    font-size: 32px;
  }

  @media (min-width: 1200px) {
    font-size: 42px;
  }

}

.person {
  font-family: var(--secondaryFontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  font-style: italic;
  text-align: right;

  padding-right: 22px;
}

.quoteLeft {
  @apply --marketplaceH1FontStyles;
  position: absolute;

  top: -30px;
  left: -30px;

  font-size: 100px;

  padding: 0;
  margin: 0;

  color: var(--matterColorNegative);

  @media (--viewportMedium) {
    top: -30px;
    left: -70px;
  }
}

.quoteRight {
  @apply --marketplaceH1FontStyles;
  position: absolute;

  bottom: -90px;
  right: -30px;

  font-size: 100px;

  padding: 0;
  margin: 0;

  color: var(--matterColorNegative);

  @media (--viewportMedium) {
    bottom: -90px;
    right: -50px;
  }

}