@import '../../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);
  margin-top: 0;
}

.listings {
  /* padding: 0 24px 96px 24px; */
  margin-top: 32px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media (--viewportLarge) {
    /* padding: 0 36px 96px 36px; */
  }
}

.listingCard {
  margin-bottom: 36px;
  flex-basis: 100%;

  @media (--viewportMedium) {
    flex-basis: calc(50% - 24px);
    margin-right: 24px;
  }

  @media (--viewportLarge) {
    flex-basis: calc(33% - 24px);
    margin-right: 24px;
  }

  /* @media (--viewportXLarge) {
    flex-basis: calc(25% - 16px);
    margin-right: 24px;
  } */
}

.listingCard:not(:last-child) {
  margin-right: 24px;
}

/**
 * Remove margin-right from listingCards on the last column
 * This is a special case in the world of breakpoints. Nth-of-type effects every nth item.
 * Here we take margin-right away, but we don't want it affect the next column breakpoint.
 */

 .listingCard:nth-of-type(4n) {
  @media screen and (min-width: 1024px) and (max-width: 1920px) {
    margin-right: 0;
  }
}

.listingCard:nth-of-type(4n) {
  @media (--viewportXLarge) {
    margin-right: 0;
  }
}