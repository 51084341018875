@import '../../../marketplace.css';
@import '../../../marketplaceFonts.css';

:root {
    --backgroundImage: {
        background:  url('./images/Dallas.jpeg');

        /* Add loading color for the div */
        /* background-color: var(--matterColor); */
        
        /* Cover the whole screen with the background image */
        background-size: auto 100%;
        
        /* Align the image within the container */
        background-position: right 200px;
        background-repeat: no-repeat;
      }
}

.root {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-start;

    @media (min-width: 1201px) {
        @apply --backgroundImage;
    }
    /* @apply --backgroundImage;
    width: 100%;
    height: 100%; */
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin: 0 0 2%;
    width: 70%;
}

.container {
    
    display: flex;
    flex-direction: column-reverse;

    @media (--viewportMedium) {
        flex-direction: row;
        flex-wrap: nowrap;
    }

    @media (max-width: 1200px) {
        /*  */
    }

    @media (min-width: 1200px) {
        max-width: 60%;
        padding: 80px 0 80px 0;
    }
    /* width: 100%;
    height: 100%;
    padding: 30px 0 30px 0;

    @media (--viewportMedium) {
        width: 65%;
    }

    @media (min-width: 1200px) {
        width: 60%;
        padding: 80px 0 80px 0;
    } */
}

.image {
    @apply --backgroundImage;

    background-position: center 22%;

    flex-basis: 100%;
    background-size: 100% auto;
    padding-bottom: 250px;

    @media (--viewportMedium) {
        background-position: 70% center;
        background-size: auto 100%;
        flex-basis: 40%;
    }

    @media (--viewportLarge) {
        flex-basis: 50%;
    }

    @media (min-width: 1201px) {
        display: none;
    }
}

.content {
    position: relative;
    background-color: #F8F9FA;

    flex-basis: 100%;
    padding: 24px;

    @media (--viewportMedium) {
        flex-basis: 60%;
    }

    @media (--viewportLarge) {
        flex-basis: 50%;
    }

    @media (min-width: 1200px) {
        flex-basis: 100%;
        padding: 56px 56px 56px 150px;
        border-radius: 0 15px 15px 0;
    }

    @media (min-width: 1645px) {
        padding: 96px 96px 96px 150px;
    }
}

.grid {
    font-family: var(--secondaryFontFamily);
    display: grid;
    grid-gap: 0.5em;
    grid-template-columns: repeat(1, minmax(100%, 1fr));

    padding-bottom: 12px;

    @media (--viewportMedium) {
        grid-gap: 1em;
        padding-top: 22px;
    }

    @media (min-width: 1200px) {
        grid-gap: 2em;
        padding-top: 32px;
    }
}

.partnerWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.imageWrapper {
    flex-basis: calc(40% - 15%);
    margin-left: 15%;

    & img {
        width: 100%;
    }
}

.partnerInfo {
    flex-basis: 60%;
}

.partnerName {
    @apply --marketplaceH2FontStyles;

    @media (--viewportMedium) {
        font-size: 20px;
        margin: 0;
        padding: 0 0 4px 0;
    }

    @media (--viewportLarge) {
        font-size: 26px;
        padding: 0 0 8px 0;
    }

    @media (min-width: 1200px) {
        font-size: 28px;
    }
} 

.partnerDescription {
    line-height: 1.1em;
    font-size: 16px;

    margin: 0;

    @media (--viewportLarge) {
        font-size: 18px;
    }

    @media (min-width: 1200px) {
        font-size: 18px;
    }
}

.reference {
    position: absolute;
    padding: 24px;

    color: var(--matterColorLight);

    right: 0;
    top: 0;
    margin-top: 160px;

    text-align: right;

    @media (--viewportMedium) {
        top: auto;
        margin-top: auto;
        bottom: 0;
    }
}

.referenceTitle {
    display: block;
    font-family: var(--secondaryFontFamily);
    font-weight: var(--fontWeightBold);
    font-size: 0.9em;
}