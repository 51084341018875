@import '../../marketplace.css';

.question {
    padding-bottom: 8px;
}

.description {
    font-size: 14px;
    color: var(--matterColorAnti);
}

.optionsContainer {
    display: flex;
    align-items: center;
}

.optionsContainer span {
    margin-right: 12px;
}

.radioButton label {
    display: flex;
    flex-direction: column;
}

.submitButton {
    margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}