@import '../../../marketplace.css';

.title{
    font-size: 1.8rem;
    color: #575757;
    text-align: center;
    
}

.grid {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(1, minmax(100%, 1fr));
    width: 100%;

    @media (--viewportMedium) {
        grid-template-columns: repeat(3, minmax(30%, 1fr));
    }

    margin-top: 56px;
    margin-bottom: 56px;
}

.didYouKnowContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    padding: 24px;

    @media (min-width: 1200px) {
        padding: 24px;
    }

    @media (min-width: 1681px) {
        padding: 96px;
    }
}

.didYouKnowContainer:nth-child(1){
    border-bottom: 1px solid gray;

    @media (--viewportMedium) {
       /* border-right: 1px solid gray; */
        border-bottom: 0;
    }
}
.didYouKnowContainer:nth-child(2){
    border-bottom: 1px solid gray;

    @media (--viewportMedium) {
       /* border-right: 1px solid gray; */
        border-bottom: 0;
    }
}

.porcentage {
    font-size: 4rem;
    font-style: italic;
    height: 50px;
    color: #575757;
}

.subtitle {
    font-weight: var(--fontWeightBlack);
    font-size: 1.5rem;

    margin-bottom: 7px;

    color: #575757;

    width: 50%;

    @media (--viewportMedium) {
        width: 100%;
    }

    @media (--viewportLarge) {
        width: 80%;
    }
}

.entity {
    font-size: 60%;
}

.description {
    margin-top: 42px;
    font-family: var(--secondaryFontFamily);

    width: 80%;
}