@import '../../../marketplace.css';
@import '../../../marketplaceFonts.css';

.root {
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
    }

    @media (min-width: 1681px) {
        /* padding: 0 25%; */
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin: 0;
    margin-right: auto;
}

.grid {
    display: grid;
    grid-gap: 2em;
    row-gap: 1em;
    grid-template-columns: repeat(2, minmax(calc(50% - 2em), 1fr));

    margin: 64px 0 32px;
    width: 100%;

    @media (--viewportMedium){
        grid-template-columns: repeat(3, minmax(calc(33% - 2em), 1fr));
    }
}

.blueText{
    color: var(--marketplaceColor);
    font-weight: var(--fontWeightBold);
    margin: 0;
}