@import '../../marketplace.css';

.input {
  /* border-bottom-color: var(--attentionColor); */
  border-bottom-color: var(--marketplaceSecondaryColor);
}

.inputSuccess {
  /* border-bottom-color: var(--successColor); */
  border-bottom-color: var(--marketplaceColor);
}

.inputError {
  border-bottom-color: var(--failColor);
}
