@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.aside {
  box-shadow: none;
  padding: 24px 50px 0 50px;

  @media (--viewportMedium) {
    padding: 112px 50px 50px 50px;
  }
}

.asideContent {
  width: 100%;
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  border-bottom: 1px solid var(--matterColorNegative);
  align-items: center;

  @media (--viewportMedium) {
  }

  @media (--viewportLarge) {
    flex-direction: column;
    border-bottom: none;
  }
}

.avatar {
  /* margin: 30px 26px 29px 0; */
  /* margin: 30px 26px 0 0; */
  flex-shrink: 0;

  @media (--viewportLarge) {
    /* margin: 0 96px 44px 0; */
    /* margin: 0 96px 0 0; */
  }
}

.heading {
  flex-shrink: 0;
  margin: 25px 0 0 0;

  @media (--viewportMedium) {
    /* margin: 25px 0 0 0; */
    margin: 32px 0 0 0;
  }
  @media (--viewportLarge) {
    /* display: none; */
  }
}

.joinedDate {
  margin: 0 0 14px 0;

  @media (--viewportMedium) {
    margin: 0 0 24px 0;
  }
}

.editLinkMobile {
  margin-top: 17px;

  /* Pull the link to the end of the container */
  margin-left: auto;

  @media (--viewportMedium) {
    margin-top: 20px;
  }
  @media (--viewportLarge) {
    display: none;
  }
}

.editLinkDesktop {
  display: none;

  @media (--viewportLarge) {
    display: inline;
  }
}

.desktopHeading {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 0 0;
  }
}

.desktopHeadingWithMargins {
  display: none;

  @media (--viewportLarge) {
    display: block;
    margin: 2px 0 24px 0;
  }
}

.verifiedIconsDesktop {
  display: none;

  @media (--viewportLarge) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin: 0 0 24px 0;
  }
}

.verifiedIconsMobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0 0 24px 0;

  @media (--viewportLarge) {
    display: none;
  }
}

.verifiedIcon {
  display: flex;
  position: relative;

  width: 50%;
  /* margin-top: -15px; */

  @media (--viewportSmall) {
    width: 200px;
  }

  & img {
    width: 100%;
    cursor: pointer;
  }
}

.helpContainer {
  margin-top: 12px;
  & p {
    margin-left: 15px;
  }
}

.helpTitle {
  font-size: 12px;
  line-height: 1.1em;

  padding: 0;
  margin-bottom: 2px;
  margin-top: 12px;
}

.helpText {
  /* @apply --marketplaceTinyFontStyles; */
  font-size: 12px;
  line-height: 1.1em;

  margin: 0 0 5px 0;
}

.bio {
  /* Preserve newlines, but collapse other whitespace */
  white-space: pre-line;
  margin: 5px 0 24px 0;
  font-weight: var(--fontWeightSemiBold);

  @media (--viewportMedium) {
    margin: 4px 0 36px 0;
  }
  @media (--viewportLarge) {
    margin: 0 0 48px 0;
    max-width: 600px;
  }
}

.listingsContainer {
  @apply --clearfix;
  border-top: 1px solid var(--matterColorNegative);
  border-bottom: 1px solid var(--matterColorNegative);
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.withBioMissingAbove {
  /* Avoid extra borders in mobile when bio is missing */
  border-top: none;

  @media (--viewportLarge) {
    border-top: 1px solid var(--matterColorNegative);
  }
}

.listings {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.listing {
  width: 100%;

  /* Single column in mobile */
  margin-top: 34px;
  &:first-of-type {
    margin-top: 18px;
  }

  @media (--viewportMedium) {
    /* Two columns in desktop */
    width: calc(50% - 12px);
    margin-bottom: 36px;

    /* Horizontal space */
    &:nth-of-type(odd) {
      margin-right: 12px;
    }
    &:nth-of-type(even) {
      margin-left: 12px;
    }

    /* Vertical space */
    margin-top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      margin-top: 20px;
    }
  }
}

.mobileReviewsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin: 24px 0 26px 0;
}

.mobileReviews {
  margin-bottom: 36px;
}

.desktopReviews {
  margin-top: 28px;

  @media (--viewportMedium) {
    margin-bottom: 48px;
  }

  @media (--viewportLarge) {
    max-width: 600px;
    margin-bottom: 0;
  }
}

.desktopReviewsTitle {
  @apply --marketplaceH3FontStyles;
  font-family: 'Archivo Narrow', sans-serif;
  margin-bottom: 0;
}

.desktopReviewsTabNav {
  margin-bottom: 32px;
}

.asideItem {
  margin-bottom: 12px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 24px;
  }
}

.asideItemTitle {
  font-family: 'Archivo Narrow', sans-serif;
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  text-transform: uppercase;
  margin: 0;
}

.asideItemValue {
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
  text-transform: capitalize;
}

.certifications{
  margin-top: 12px;
  margin-bottom: 24px;
}