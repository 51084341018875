@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --SectionHero_desktopTitleMaxWidth: 625px;

  --SectionHero_animation: {
    -webkit-animation-name: animationTitle;
    animation-name: animationTitle;

    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;

    -webkit-animation-timing-function: ease-out;
    -webkit-animation-fill-mode: forwards;
    animation-timing-function: ease-out;

    visibility: hidden;
    opacity: 1;
    position: relative;
  }

  --button: {
    @apply --marketplaceButtonStyles;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;

    font-size: 13px;
    font-weight: bold;
    line-height: 0;
    padding: 0;

    border-radius: var(--borderRadiusMedium);

    min-height: 35px;
    height: 35px;

    @apply --SectionHero_animation;
    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;

    -ms-flex-preferred-size: 100%;
    -webkit-flex-basis: 100%;
    flex-basis: 100%;

    @media (--viewportMedium) {
      -ms-flex-preferred-size: calc(50% - 25px);
      -webkit-flex-basis: calc(50% - 25px);
      flex-basis: calc(50% - 25px);
      min-height: 45px;
      height: 45px;
    }

    @media (--viewportLarge) {
      font-size: 18px;
      min-height: 50px;
      height: 50px;
    }
  }
}

/* Animation for the hero elements */

@-webkit-keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

.root {
  /* Add loading color for the div */
  background-color: var(--matterColorLight);

  display: flex;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
  width: 100%;
  /* height: 250px; */
  
  position: relative;

  @media (--viewportLarge) {
    -ms-flex-direction: row;
    flex-direction: row;

    height: 100%;
    margin: 0;
  }

  /* Text positioning is done with paddings */
}

.heroContent {
  width: 100%;
  background-color: var(--matterColorLight);
  border-radius: 0 0 0 0;
  height: fit-content;
  display: flex;
  flex-direction: column;

  padding: 24px 24px 24px 24px;

  margin: auto 0;

  @media (--viewportSmall) {
    padding: 50px 50px 50px 50px;
  }

  @media (--viewportMedium) {
    border-radius: 0 0 50px 0;
    padding: 25px 50px 25px 50px;
  }

  @media (--viewportLarge) {
    width: 50%;
    padding: 45px 75px 45px 150px;
  }

  @media (min-width: 1681px) {
    padding: 25px 200px 25px 200px;
  }
}

.heroMainTitle {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColor);
  margin-bottom: 15px;

  font-size: 28px;
  line-height: 1.1em;

  @media (--viewportMedium) {
    font-size: 54px;
  }

  @media (--viewportLarge) {
    font-size: 64px;
  }

  @media (--viewportXLarge) {
    font-size: 72px;
  }
}

.heroSubTitle {
  font-family: var(--secondaryFontFamily);
  color: var(--matterColor);

  font-size: 20px;
  line-height: 1.5em;

  @media (--viewportMedium) {
    margin-bottom: 40px;
  }

  @media (--viewportXLarge) {
    padding-right: 15%;
    width: 100%;
  }
}

.buttonsWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.getStarted {
  @apply --button;
  font-size: 16px;

  background-color: var(--marketplaceSecondaryColor);
  border-radius: 15px;
  margin-bottom: 18px;
  height: 64px;

  @media (--viewportMedium) {
    margin-bottom: 0;
    width: 80%;
  }

  @media (--viewportXLarge) {
    margin-bottom: 0;
    width: 90%;
  }

  &:hover {
    color: var(--matterColorLight);
    background-color: var(--marketpalceSecondaryColorDark);
  }
}

.listYourSpace {
  @apply --button;
  font-size: 16px;
  background-color: var(--matterColorLight);
  color: var(--marketplaceSecondaryColor);

  border: 1px solid var(--marketplaceSecondaryColor);
  border-radius: 15px;
  height: 64px;

  &:hover {
    background-color: var(--matterColorLight);
    color: var(--marketpalceSecondaryColorDark);
    border-color: var(--marketplaceSecondaryColorDark);
  }

  @media (--viewportMedium) {
    width: 75%;
  }

  @media (--viewportXLarge) {
    width: 85%;
  }

}

.videoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  @apply --SectionHero_animation;
  animation-delay: 0.8s;
  -webkit-animation-delay: 0.08s;
  max-width: 100vw;
}

.videoImage {
  width: 8%;

  @media (--viewportMedium) {
    width: 12%;
  }
}

.videoLink {
  color: var(--marketplaceColorLight);
  margin: auto 0;
  font-size: 18px;
  font-weight: bold;
}

.reference {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0 12px 22px 0;
  color: var(--matterColorLight);
  text-align: right;
  width: 150px;

  @media (--viewportSmall) {
    width: 100%;
    padding: 32px;
  }
}

.referenceTitle {
  display: block;
  font-family: var(--secondaryFontFamily);
  font-weight: var(--fontWeightBold);
  font-size: 1.25em;
  margin-bottom: 2px;
}

.referenceLocation {
  display: block;
}

.button {
  background: none;
  text-align: left;
  color: var(--matterColor);
  text-decoration: underline;
}

.button:hover {
  background: none;
  box-shadow: none;
  text-decoration: underline;
}

.videoContainer {
  @media(--viewportSmaller) {
    position: relative;
    padding-bottom: 56.25%;
    /*panorámico*/
    padding-top: 25px;
    height: 0;
  }
}

.video {

  width: 720px;
  height: 404px;

  @media(--viewportSmaller) {
    margin-top: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.imageContainer {
  width: 100%;
  padding: 24px 24px 0;

  @media (--viewportLarge) {
    width: 50%;
    padding: 24px 75px 24px 12px;
  }
}

.heroImage {
  width: 100%; /* image box size as % of container, see step 1 */
  height: 100%; /* image box size as % of container, see step 1 */
  object-fit: cover; 
  object-position: left;
  border-radius: 32px;
}