@import '../../marketplace.css';

.title {
    @apply --marketplaceEditListingPanelTitleFontStyles;
    margin-bottom: 19px;
  
    @media (--viewportLarge) {
      margin-bottom: 38px;
      padding: 1px 0 7px 0;
    }
  }