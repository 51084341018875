@import '../../marketplace.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }
}

.threeToTwoWrapper {
  /* Layout */
  display: block;
  width: 100%;
  position: relative;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowListingCard);
  }
}

.behindTopBar {
  z-index: -1;
}

/* Firefox doesn't support image aspect ratio inside flexbox */
.aspectWrapper {
  padding-bottom: 66.6667%; /* 3:2 Aspect Ratio */
  background: var(--matterColorNegative); /* Loading BG color */
}

.rootForImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  border-radius: var(--borderRadius);
}

/* .spaceType {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorNegative);
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px;
  z-index: 1;
} */

.addToFavorite {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: var(--transitionStyleButton);
  z-index: 1;

  & > i {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.2em;
    color: var(--matterColorDark);
  }

  &:hover {
    transform: scale(1.1);
  }
}

.isFavorite {
  & > i {
    color: var(--marketplaceSecondaryColor);
  }
}

.spaceType {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  margin: 0;
  padding: 0;
  flex-basis: 100%;
}

.info {
  /* Layout */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* padding: 16px 0 2px 0; */
  padding: 3px 0 2px 0;
  position: relative;
}

.activitiesWrapper {
  width: 100%;
  display: block;
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}

.activitiesTitle {
  display: inline-block;
  font-weight: var(--fontWeightBold);
  color: var(--matterColorDark);
}

.prices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: var(--successColorLight);
  margin-bottom: 2px;
}

.price {
  @apply --marketplaceTinyFontStyles;
  background: var(--marketplaceColor);
  font-size: 0.75rem;
  font-weight: var(--fontWeightSemiBold);
  padding: 3px 7px;
  margin: 5px 0;
  border: 2px var(--marketplaceColor) solid;
  border-radius: 10px;
}

.price:not(:last-child) {
  margin-right: 7px;
}

.priceValue {
  /* Font */
  @apply --marketplaceH3FontStyles;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.perUnit {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  /* Font */
  @apply --marketplaceH2FontStyles;
  color: var(--matterColorDark);
  line-height: 1.3rem;

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.authorInfo {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColor);

  /* Remove default margins from font */
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.longWord {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}
