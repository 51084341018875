.content{
    margin: 24px auto 0 auto;
    padding: 0 24px 96px 24px;

    @media (--viewportMedium) {
      margin: 48px auto 0 auto;
    }
  
    @media (--viewportLarge) {
      padding: 0 36px 36px 36px;
      margin: 80px auto 0 auto;
      max-width: 62.5vw;
    }
}

.captcha{
    font-style: italic;
    font-size: 12px;
}

.image{
    width: 64px;
    height: auto;
}