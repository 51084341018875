@import '../../../marketplace.css';
@import '../../../marketplaceFonts.css';

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.textInfo{
    width: 100%;
    color: var(--matterColor);

    @media (--viewportMedium){
        width: 40%;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    margin: 0;
}

.content {
    margin: 22px 0;
    padding-right: 4%;
}

.content p{
    font-weight: 600;
    font-family: var(--secondaryFontFamily);
    font-size: 1.2em;
}

.buttonsWrapper {
    margin: auto;
    width: 100%;

    @media (--viewportMedium){
        width: 60%;
    }
}

.searchBar{
    width: 100%;
    flex-grow: 1;
    height: 100%;
    margin-right: 24px;
    display: flex;
  
    @media (--viewportLarge) {
      padding-left: 0;
    }
}

.desktopInputRoot{
    border: 1px solid var(--matterColor);
    box-shadow: 0 3px 5px rgb(0 0 0 / 0.2);
    width: 100%;
    padding: 8px 18px;
    border-radius: 35px;
    margin: auto 0;
    height: 72px;
}

.circle{
    background-color: var(--matterColorAnti);
    color: var(--matterColorLight);
    padding: 12px;
    width: 48px;
    height: 48px;
    text-align: center;
    border-radius: 100%;
    margin-bottom: 12px;
}