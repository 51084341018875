@import '../../marketplace.css';

:root {
  --button: {
    @apply --marketplaceButtonStyles;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    font-size: 16px;
    font-weight: bold;
    line-height: 0;
    padding: 2.5%;

    border-radius: 10px;

    animation-delay: 0.8s;
    -webkit-animation-delay: 0.8s;

    -ms-flex-preferred-size: 100%;

    flex-basis: 100%;
    -webkit-flex-basis: 100%;

    @media (--viewportMedium) {
      -ms-flex-preferred-size: calc(50% - 25px);
      flex-basis: calc(50% - 25px);
      -webkit-flex-basis: calc(50% - 25px);
      max-height: 35px;
      height: 45px;
      background-size: 150% auto;
    }

    @media (--viewportLarge) {
      font-size: 18px;
      max-height: 35px;
      height: 35px;
    }

  }
}


.root {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: var(--marketplaceColorBright);
  width: 85%;
  height: 100%;
  margin: auto;
  justify-content: center;

  background-size: 300% auto;

  @media (min-width: 812px) {
    background-size: 150% auto;
  }

  @media (min-width: 1122px) {
    background-size: 130% auto;
  }

  @media (min-width: 1378px) {
    background-size: 100% auto;
  }
}

.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  width: 100%;

  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  -webkit-box-pack: center;

  -ms-flex-pack: center;

  justify-content: center;
  padding: 2.5% 24px;
  
  @media (--viewportMedium){
    width: 60%;
  }
}


.title {
  @apply --marketplaceH1FontStyles;
  font-weight: bold;
  color: var(--matterColor);

  @media (--viewportMedium) {
    font-size: 28px;
    margin: 0;
    padding: 0;
  }

  @media (--viewportLarge) {
    font-size: 36px;
    margin-bottom: 6px;
  }

  @media (min-width: 1201px) {
    font-size: 36px;
  }
}

.description {
  @apply --marketplaceLandingPageSubtitleFontStyles;
  color: var(--matterColor);
  font-size: 20px;
  line-height: 22px !important;
  font-weight: var(--fontWeightSemibold);

  width: 100%;
  margin-top: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    margin-top: 0;
    width: 80%;
  }

}

.learnMore {
  @apply --button;
  background-color: var(--matterColor);
  max-height: 15%;
  min-height: 0;
  margin-bottom: 24px;

  @media (--viewportMedium) {
      width: 50%;
      padding: 24px;
      margin-bottom: 0;
  }
}

.image{
    width: 90%;
    padding: 24px 0;

    @media (--viewportMedium){
        width: 25%;
        height: 25%;
        padding: 24px 0;
    }
}

.learnMoreText{
    width:100%;
}