@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  max-width: 735px;

  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.locations {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(2, minmax(45%, 1fr));

  margin-top: 32px;

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, minmax(20%, 1fr));
  }
}

.location {
  width: 100%;
  margin-top: 25px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}


.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(1 / 1 * 100%); /* 1:1 Aspect Ratio */

  @media (--viewportMedium) {
    /* padding-bottom: calc(16 / 9 * 100%); 9:16 Aspect Ratio */
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  @apply --marketplaceH2FontStyles;
  color: var(--matterColor);
  margin-top: 15px;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 21px;
  }
}

.locationName {
  @apply --marketplaceH1FontStyles;
  color: var(--matterColorLight);

  position: absolute;

  bottom: 0;
  left: 0;
  /* width: 100%; */
  text-align: left;

  font-size: 22px;
  line-height: 1em; 

  padding: 0 0 15px 15px;
  margin: 0;

  @media (--viewportSmall) {
    font-size: 30px;
  }
  
  @media (--viewportMedium) {
      font-size: 1em;
  }
  @media (--viewportLarge) {
      font-size: 1.4em;
  }
  @media (min-width: 1681px) {
      font-size: 2em;
  }
}