@import '../../../marketplace.css';
@import '../../../marketplaceFonts.css';

:root {

      --button: {
        @apply --marketplaceButtonStyles;
    
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    
        font-size: 13px;
        font-weight: bold;
        line-height: 0;
        padding: 0;
    
        border-radius: 12px;
    
        min-height: 35px;
        height: 50px;
    
        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;
    
        @media (--viewportMedium) {
          -ms-flex-preferred-size: calc(50% - 25px);
          -webkit-flex-basis: calc(50% - 25px);
          flex-basis: calc(50% - 25px);
          min-height: 45px;
          height: 60px;
        }
    
        @media (--viewportLarge) {
          font-size: 18px;
          min-height: 65px;
          height: 65px;
        }
      }
}

.root {
    width: 100%;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
    }

    @media (min-width: 1681px) {
        /* padding: 0 25%; */
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin: 0;
    margin-right: auto;
}

.grid {
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(1, minmax(100%, 1fr));

    margin: 64px 0 32px;

    @media (--viewportMedium){
        grid-template-columns: repeat(3, minmax(calc(33% - 2em), 1fr));
    }
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.iconWrapper {
    flex-basis: 40px;
    margin-right: 15px;
}

.cellTitle{
    font-weight: var(--fontWeightBold);
    font-size: 1.5em;
    line-height: 1em;
    margin-bottom: 22px;
}

.cellDescription{
    color: var(--matterColor);
    font-family: var(--secondaryFontFamily);
    font-size: 22px;
    font-weight: var(--fontWeightSemiBold);
    line-height: 1.5em;
}

.image{
    width: 10%;
}

.link{
    @apply --button;
    background-color: var(--marketplaceSecondaryColor);
    margin-top: 48px;
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 36px;

    @media (--viewportMedium) {
        width: 20%;
    }

    @media (--viewportXLarge) {
        width: 20%;
    }
}

.link:hover{
    color: var(--matterColorBright);
    background-color: var(--marketpalceSecondaryColorDark);
}