@import '../../marketplace.css';

.error {
  margin-top: 5px;

  @media (--viewportMedium) {
    margin-top: 4px;
  }
  @media (--viewportLarge) {
    margin-top: 3px;
  }
}

.error {
  color: var(--failColor);
}

.desktopHeading {
  /* display: none; */
  margin: 0 24px 24px 24px;

  @media (--viewportMedium) {
    margin: 0 24px 48px 24px;
  }

  @media (--viewportLarge) {
    margin: 0 36px 48px 36px;
  }
}

.listingsContainer {
  @apply --clearfix;
  /* border-top: 1px solid var(--matterColorNegative); */
  /* border-bottom: 1px solid var(--matterColorNegative); */
  padding-bottom: 23px;

  @media (--viewportMedium) {
    padding-bottom: 22px;
  }
}

.listingsTitle {
  @apply --marketplaceH3FontStyles;
  color: var(--matterColorAnti);
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 48px;
    margin-bottom: 8px;
  }
  @media (--viewportLarge) {
    margin-top: 58px;
    margin-bottom: 8px;
  }
}

.listingPanel {
  width: 100%;
  margin: 24px auto 0 auto;

  @media (--viewportMedium) {
    margin: 48px auto 0 auto;
  }

  @media (--viewportLarge) {
    margin: 80px auto 0 auto;
    max-width: 62.5vw;
  }
}

.listingCards {
  padding: 0 24px 96px 24px;

  @media (--viewportMedium) {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: repeat(2, minmax(calc(50% - 1em), 1fr));
  }

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listingCard {
  margin-bottom: 36px;
  width: 100%;
}