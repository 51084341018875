@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.root {
}

.pageHeaderContainer {
  background:  url('./images/357FdueA.jpeg');
  background-position: center;
  background-size: cover;
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  /* background-color: var(--matterColorLight); */
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    padding: 24px 24px 0 24px;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    max-width: 1760px;
    padding: 0 150px 0 150px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

.sectionTagsContainer{
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    padding: 24px 24px 0 24px;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 150px 0 150px;
    margin: 125px auto 125px auto;
  }
}

.spaceTypesContainer {
  max-width: 100%;

  @media (--viewportLarge) {
    max-width: 1760px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 5px auto;
  }
}

.sectionGetStartedContainer {
  margin: var(--LandingPage_sectionMarginTop) auto 51px auto;

  @media (--viewportMedium) {
    margin: var(--LandingPage_sectionMarginTopMedium) auto 60px auto;
  }

  @media (--viewportLarge) {
    margin: var(--LandingPage_sectionMarginTopLarge) 0 130px auto;
  }

  @media (min-width: 1645px) {
    max-width: 1760px;
    margin: var(--LandingPage_sectionMarginTopLarge) 0 175px auto;
  }
}

.faqSection {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    padding: 24px 24px 0 24px;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1760px;
    padding: 94px 150px 94px 150px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }
}

.greyBg{
  background: var(--marketplaceColorGrey);
}

.circle{
  background-color: var(--matterColorAnti);
  color: var(--matterColorLight);
  padding: 12px;
  width: 48px;
  height: 48px;
  text-align: center;
  border-radius: 100%;
  margin-bottom: 12px;
}