@import '../../marketplace.css';

:root {
  --containers: {
      display: flex;
      justify-content: space-between;
      width: 100%;
  };

  --column: {
    flex-basis: calc(40% - 12px);
    margin: 0;
    padding-bottom: 1px;
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.submitButton {
    margin-top: auto;
    margin-bottom: 24px;
  
    @media (--viewportLarge) {
      display: inline-block;
      width: 241px;
      margin-top: 100px;
    }
}

.labels {
  @apply --containers;
  margin-bottom: 24px;
}

.descriptions {
  @apply --containers;
  margin-bottom: 24px;
}

.selectors {
  @apply --containers;
}

.label {
  @apply --column;

  font-family: var(--secondaryFontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 38px
}

.description {
  @apply --column;

  & h3 {
    font-family: var(--primaryFontFamily);
    font-weight: var(--fontWeightMedium);
  }

  &  p {
    font-size: 16px;
    font-style: italic;
    line-height: 1em;
    color: var(--matterColor);
  }
}

.selector {
  @apply --column;
  display: flex;
  justify-content: center;
  align-items: center;
}

.error{
  color: var(--marketplaceSecondaryColor);
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
}

.error:last-child{
  margin-bottom: 24px;
}