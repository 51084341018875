@import '../../marketplace.css';
@import '../../marketplaceFonts.css';

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    text-align: center;
     
    @media (--viewportMedium) {
        font-size: 32px;
        margin: 0;
        padding: 0;
    }

    @media (--viewportLarge) {
        font-size: 40px;
        margin-bottom: 12px;
    }

    @media (min-width: 1201px) {
        font-size: 48px;
    }
}

.tab{
    font-family: var(--secondaryFontFamily);
    font-size: 20px;
    font-weight: bold;
    padding: 0 12px;
    line-height: 32px;
}

.tabButton{
    background-color: var(--matterColorBright);
    color: var(--matterColor);
    border-style: solid;
    border-radius: 6px;
    border-color: transparent;
}

.tabButtonSelected{
    background-color: var(--matterColor);
    color: var(--matterColorBright);
    border-style: solid;
    border-radius: 6px;
    border-color: transparent;
}

.tabsContainer{
    justify-content: center;
}

.tabs{
    margin: 32px 32px;
}

.searchTabs{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 80%;
    margin: auto;
}

.recentSearches{
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 6px 0;

    @media (--viewportMedium){
        width: 50%;
    }

    @media (--viewportLarge){
        width: 33%;
    }
}

.tabContainer{
    background-color: var(--matterColorAntiTransparent);
    border-radius: 12px;
    color: var(--matterColor);
    width: 100%;
    padding: 12px;
    margin: auto;

    @media (--viewportMedium){
        width: 90%;

    }
}

.tabContainer:hover{
    color: var(--matterColor);
}

.spaceTitle{
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    line-height: 20px;
    font-family: var(--secondaryFontFamily);
}

.tagsSection{
    display: flex;
    flex-direction: row;
}

.tagsTitle{
    font-family: var(--secondaryFontFamily);
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin: 0;
}

.tagsBody{
    font-family: var(--secondaryFontFamily);
    font-size: 12px;
    margin: 0;
    margin-left: 6px;
    line-height: 16px;
}