@import '../../../marketplace.css';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    @media (min-width: 1200px) {
        padding: 0 10%;
    }

    @media (min-width: 1681px) {
        padding: 0 25%;
    }
}

.preTitle {
    color: var(--matterColor);
    font-size: 0.8rem;
    margin: 0;
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin: 0 0 2%;
    width: 70%;
}

.content {
    color: var(--matterColor);
    padding: 0 16%;
    margin: 22px 0;
}