@import '../../marketplace.css';

.root {
  /* Layout */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  /* Content of EditListingWizard should have smaller z-index than Topbar */
  z-index: 0;
}

.tabsContainer {
  flex-grow: 1;
  /* Layout */
  width: 100%;
  height: 100%;
  margin-right: 15px;

  display: flex;
  flex-direction: column;

  @media (--viewportLarge) {
    flex-direction: row;
    justify-content: center;
  }
}

.nav {
  /* Layout */
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-shrink: 0;
  background-color: var(--matterColorLight);
  box-shadow: var(--boxShadowLight);
  border-top: 1px solid var(--matterColorNegative);
  overflow-x: scroll;

  @media (--viewportLarge) {
    padding: 128px 0 82px 36px;
    flex-direction: column;
    overflow-x: auto;
    background-color: var(--matterColorBright);
    box-shadow: none;
    border-top: none;
  }

  @media (--viewportLargeWithPaddings) {
    padding: 128px 0 82px calc((100% - 1056px) / 2);
  }
}

.tab {
  margin-left: 16px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0;

    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-left: 24px;
  }

  &:last-child {
    /* Padding added to tab so that it will be visible after call to scrollToTab */
    padding-right: 24px;
  }

  @media (--viewportLarge) {
    margin-left: 0;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    
  }
}

.panel {
  flex-grow: 1;

  @media (--viewportLarge) {
    padding: 88px 36px 82px 59px;
    border-left: 1px solid var(--matterColorNegative);
    background-color: var(--matterColorLight);
  }

  @media (--viewportLargeWithPaddings) {
    padding: 88px calc((100% - 1056px) / 2) 82px 59px;
  }
}

.payoutDetails {
  margin-bottom: 100px;

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.modalTitle {
  @apply --marketplaceModalTitleStyles;
  font-weight: 700;
  text-align: center;
}

.modalPayoutDetailsWrapper {
  @media (--viewportMedium) {
    width: 604px;
    padding-top: 11px;
  }
}

.navHeader {
  color: var(--marketplaceSecondaryColor);
  font-size: var(--fontSizeLarge);
  width: 65%;
}

.navHeader:hover{
  color: var(--failColor);
}

.icon{
  width: 16px;
  height: 16px;
  margin-left: 6px;
  filter: invert(60%) sepia(17%) saturate(4021%) hue-rotate(312deg) brightness(89%) contrast(91%);
}

.description{
  color: var(--matterColor);
  font-size: 18px;
  line-height: 18px;
  letter-spacing: -0.1px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  margin: 16px 0;
  padding: 0 24px;
}

.subMessage{
  font-size: 16px;
  font-weight: 700;
  color: var(--matterColorAnti);
  text-align: center;
  width: 100%;
  margin: 32px 0 16px;

  & a{
    color: var(--matterColorAnti);
    text-decoration: underline;
  }
}

.image{
  width: 180px;
  margin: 24px auto;
}

.verifiedIcon {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;

  & img {
    width: 100%;
    cursor: pointer;
    margin-bottom: 12px;
  }
}

.helpContainer{
  width: 75vw;
  text-align: center;

  @media(--viewportMedium){
    width: 400px;
  }
}

.helpTitle {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1em;

  padding: 0;
  margin-bottom: 2px;
  margin-top: 12px;
}

.helpText {
  /* @apply --marketplaceTinyFontStyles; */
  font-size: 16px;
  line-height: 1.1em;
  letter-spacing: 0.5px;

  margin: 0 0 5px 0;
}

.helpTextMarked {
  /* @apply --marketplaceTinyFontStyles; */
  font-size: 14px;
  line-height: 1.1em;
  letter-spacing: 0.5px;

  margin: 0 0 5px 0;
}
