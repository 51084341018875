@import '../../../marketplace.css';

.root {
    width: 100%;
    margin: 0 auto;

    @media (--viewportSmall) {
        width: 85%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: 1200px) {
        padding: 0 10%;
    }

    @media (min-width: 1681px) {
        padding: 0 25%;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin: 0 0 2%;
    align-self: flex-start;
}

.description {
    font-family: var(--secondaryFontFamily);
    line-height: 1.1em;
}

.rootContactForm {
    border: 1px solid var(--matterColor);
    border-radius: var(--borderRadiusPanel);

    padding: 24px;
    width: 100%;

    @media (--viewportSmall) {
        padding: 48px;
    }
}