@import '../../marketplace.css';

:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */

  --EditListingDescriptionForm_formMargins: {
    margin-bottom: 24px;

    @media (--viewportMedium) {
      margin-bottom: 32px;
    }
  }
}

.root {
  /* Dimensions */
  width: 100%;
  height: auto;

  /* Layout */
  display: flex;
  flex: 1;
  flex-direction: column;

  padding-top: 1px;

  @media (--viewportMedium) {
    padding-top: 2px;
  }
}

.error {
  color: var(--failColor);
}

.field {
  @apply --EditListingDescriptionForm_formMargins;

  & label,
  & legend {
    @apply --marketplaceEditListingAttributeTitleFontStyles;
  }
}

.input {
  @apply --marketplaceEditListingAttributeValueFontStyles;
}

.activitiesText {
  @apply --marketplaceEditListingCheckboxItemFontStyles;
}

.listActivities {
  display: flex;
  flex-wrap: wrap;

  & li {
    padding: 0;
    margin-right: 16px;
  }
}

.submitButton {
  margin-top: auto;
  margin-bottom: 24px;
  flex-shrink: 0;

  @media (--viewportLarge) {
    display: inline-block;
    width: 241px;
    margin-top: 100px;
  }
}
