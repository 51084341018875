@import '../../marketplace.css';

:root {
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  /* background-color: var(--matterColorLight); */
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    padding: 24px 24px 0 24px;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    /* max-width: 1128px; */
    max-width: 1760px;
    padding: 0 75px;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
    /* margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto; */
  }
}

.lastSection {
  margin-bottom: var(--LandingPage_sectionMarginTop) !important;

  @media (--viewportMedium) {
    margin-bottom: var(--LandingPage_sectionMarginTopMedium) !important;
  }

  @media (--viewportLarge) {
    margin-bottom: var(--LandingPage_sectionMarginTopLarge) !important;
  }
}

.sectionEarnMoney {
  margin: 0 auto 51px auto;

  @media (--viewportMedium) {
    margin: 0 auto 60px auto;
  }

  @media (--viewportLarge) {
    margin: 0 auto auto auto;
  }

  @media (min-width: 1645px) {
    margin: 0 auto 5px auto;
  }
}

.howHostingWorksSection {
  background-color: #F8F9FA;
}

.sectionPaymentsMadeEasyContainer {
  margin: var(--LandingPage_sectionMarginTop) auto 51px auto;

  @media (--viewportMedium) {
    margin: var(--LandingPage_sectionMarginTopMedium) auto 60px auto;
  }

  @media (--viewportLarge) {
    margin: var(--LandingPage_sectionMarginTopLarge) auto auto auto;
  }

  @media (min-width: 1645px) {
    max-width: 1760px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 5px auto;
  }
}

.listings {
  /* padding: 0 24px 96px 24px; */
  margin-top: 32px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-basis: 33.33%;
  }

  @media (--viewportLarge) {
    /* padding: 0 36px 96px 36px; */
  }
}