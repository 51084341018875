@import '../../../marketplace.css';

.container {

    @media (--viewportMedium) {
        padding: 0 10%;
    }

    @media (min-width: 1681px) {
        padding: 0 15%;
    }
}

.preTitle {
    color: var(--matterColor);
    font-weight: bolder;
    font-size: 0.8rem;
    margin: 0;

    text-align: center;
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin: 0;

    text-align: center;
}

.content {
    display: grid;
    gap: 1.5em;
    grid-template-columns: repeat(1, minmax(calc(50% - 1.5em), 1fr));

    margin: 22px 0;

    @media (--viewportMedium) {
        grid-template-columns: repeat(2, minmax(calc(50% - 1.5em), 1fr));
    }

    @media (--viewportLarge) {
        grid-template-columns: repeat(4, minmax(calc(25% - 1.5em), 1fr));
    }
}

.card{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: center;

    width: 100%;
    height: 100%;

    border-radius: var(--marketplaceBorderRadius);

    @media(--viewportMedium){
        justify-content: left;
        text-align: left;
        align-items: flex-start;
    }
}

.icon{
    width: 48px;
    height: 48px;
    margin: 12px 0;

    @media(--viewportMedium){
        margin: 24px 0;
    }
}

.cardTitle{
    @apply --marketplaceH2FontStyles;
    font-weight: 700;

    @media(--viewportLarge){
        height: 64px;
    }
}

.cardBody{
    
    @media(--viewportMedium){
        width: 80%;
    }
}