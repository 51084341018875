@import '../../../marketplace.css';

:root {

    --button: {
        @apply --marketplaceButtonStyles;

        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;

        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        font-size: 13px;
        font-weight: bold;
        line-height: 0;
        padding: 0;

        border-radius: var(--borderRadiusMedium);

        min-height: 35px;
        height: 35px;

        -ms-flex-preferred-size: 100%;
        -webkit-flex-basis: 100%;
        flex-basis: 100%;

        @media (--viewportMedium) {
            -ms-flex-preferred-size: calc(50% - 25px);
            -webkit-flex-basis: calc(50% - 25px);
            flex-basis: calc(50% - 25px);
            min-height: 45px;
            height: 45px;
        }

        @media (--viewportLarge) {
            font-size: 18px;
            min-height: 50px;
            height: 50px;
        }
    }
}

.root {
    display: flex;
    flex-direction: column;
    background-color: var(--marketplaceColorGrey);
}

.first,
.third {
    display: flex;
    flex-direction: column-reverse;

    @media (--viewportMedium) {
        border-radius: 0 0 50px 0;
    }

    @media (--viewportLarge) {
        flex-direction: row-reverse;
        gap: 24px;
        margin: 164px 0; 
    }
}

.second {
    display: flex;
    flex-direction: column-reverse;

    @media (--viewportMedium) {
        border-radius: 0 0 50px 0;
    }

    @media (--viewportLarge) {
        flex-direction: row;
        gap: 48px;
        margin: 48px 0; 
    }
}

.headerContent {
    width: 100%;
    border-radius: 0 0 0 0;
    height: fit-content;
    display: flex;
    flex-direction: column;

    margin: auto 0;
    padding: 24px 24px 24px 24px;

    @media (--viewportSmall) {
        padding: 50px 50px 50px 50px;
    }

    @media (--viewportMedium) {
        padding: 25px 50px 25px 50px;
    }

    @media (--viewportLarge) {
        width: 50%;
        padding: 0 150px 0 0;
    }

    @media (min-width: 1681px) {
        padding: 0 200px 0 0;
    }
}

.secondContent {
    width: 100%;
    border-radius: 0 0 0 0;
    height: fit-content;
    display: flex;
    flex-direction: column;

    margin: auto 0;
    padding: 24px 24px 24px 24px;

    @media (--viewportSmall) {
        padding: 50px 50px 50px 50px;
    }

    @media (--viewportMedium) {
        padding: 25px 50px 25px 50px;
    }

    @media (--viewportLarge) {
        width: 35%;
        padding: 45px 0 45px 150px;
    }

    @media (min-width: 1681px) {
        padding: 25px 0 25px 200px;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColor);
    margin-bottom: 8px;

    font-size: 28px;
    line-height: 1.1em;

    @media (--viewportMedium) {
        font-size: 32px;
    }

    @media (--viewportLarge) {
        font-size: 42px;
    }

    @media (--viewportXLarge) {
        font-size: 56px;
    }
}

.subtitle {
    color: var(--matterColor);
    font-weight: var(--fontWeightRegular);
    font-size: 20px;
    line-height: 1.5em;

    @media (--viewportMedium) {
        margin-bottom: 20px;
    }

    @media (--viewportXLarge) {
        padding-right: 15%;
        width: 100%;
    }
}

.listTitle {
    margin-bottom: 8px;
}

.list {
    margin: 0;
}

.button {
    @apply --button;
    font-size: 16px;

    background-color: var(--marketplaceSecondaryColor);
    border-radius: 8px;

    margin: 18px 0;

    @media (--viewportMedium) {
        margin-bottom: 0;
        border-radius: 15px;
        width: 50%;
    }
}

.headerImage {
    width: 100%;

    @media (--viewportLarge) {
    }

    padding-left: 0;
    
    @media (--viewportLarge) {
        width: 50%;
        padding-left: 150px;
    }

    @media (min-width: 1681px) {
        padding-left: 200px;
    }
}

.secondImage {
    width: 100%;

    @media (--viewportLarge) {
        width: 65%;
    }
}

.image {
    width: 100%;
}