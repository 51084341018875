@import '../../marketplace.css';

.title {
  @apply --marketplaceH1FontStyles;

  margin-top: 0;
  max-width: 735px;

  @media (--viewportMedium) {
    margin-bottom: 23px;
  }
}

.titleLine {
  display: block;
}

.contentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.leftColumn {
  width: 55%;
}

.rightColumn {
  width: 45%;
}

.paragram {
  /* @apply --marketplaceBodyFontStyles; */
  color: var(--matterColor);
  padding-right: 35px;
  margin-bottom: 12px;
  /* line-height: 0; */
}

.buttonWrapper {
  padding-top: 36px;
}

.signUpButton {
  background-color: var(--marketplaceSecondaryColor);
  color: var(--successColorLight);
  font-weight: var(--fontWeightRegular);
  padding: 15px 65px;
  border-radius: 7px;
}

.signUpButton:hover {
  text-decoration: none;
  color: var(--successColorLight);
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(1 / 1 * 100%); /* 1:1 Aspect Ratio */
}

.image {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}