@import '../../../marketplace.css';

:root {
    --backgroundImageMobile: {
        background:  url('./images/iStock-1191910161.jpg');

        background-size: 100% auto;

        background-position: left top;
        background-repeat: no-repeat;

      }
      --backgroundImage: {
        background:  url('./images/Boxing_Gym_Image_Updated.jpg');

        background-size: auto 90%;

        background-position: left top;
        background-repeat: no-repeat;

      }
}

.root {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-end;

    @media (min-width: 1201px) {
        @apply --backgroundImage;
        margin-top: 5%;
    }
}

.container {
    width: 100%;
    background: var(--marketplaceColorBgDark);
    border-radius: 15px 0 0 15px;

    @media (max-width: 1200px) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    @media (min-width: 1201px) {
        max-width: 65%;
        margin: 50px 0 0;
    }
}

.content {
    position: relative;
    background: var(--marketplaceColorBgDark);

    flex-basis: 100%;
    padding: 24px;

    @media (min-width: 659px) {
        flex-basis: 50%;
        min-height: 400px;
    }

    @media (min-width: 1200px) {
        padding: 56px 180px 56px 56px;
        border-radius: 15px 0 0 15px;
    }

    @media (min-width: 1645px) {
        padding: 96px 180px 56px 96px;
    }
}

.title {
    @apply --marketplaceH1FontStyles;
    color: var(--matterColorBright);

    @media (--viewportMedium) {
        font-size: 32px;
        margin: 0;
        padding: 0;
    }

    @media (--viewportLarge) {
        font-size: 32px;
    }

    @media (min-width: 1201px) {
        font-size: 40px;
    }
}

.grid {
    display: grid;
    grid-gap: 48px;
    grid-template-columns: repeat(1, minmax(100%, 1fr));

    margin: 48px 0 32px 0;
}

.itemWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.infoWrapper {
    flex: 1;
    color: var(--matterColorBright);

    & h2 {
        font-family: var(--secondaryFontFamily);
        font-size: 1.5em;
        line-height: 0.75em;

        margin-bottom: 8px;
    }
}

.image {
    @apply --backgroundImageMobile;

    background-position: center center;

    @media (max-width: 658px) {
        flex-basis: 100%;
        background-size: 100% auto;
        padding-bottom: 200px;
    }

    @media (min-width: 659px) {
        @apply --backgroundImage;
        flex-basis: 50%;
        height: auto;
    }

    @media (min-width: 1201px) {
        display: none;
    }
}

.stripeLogo {
    width: 125px;
    height: auto;
}

.startHosting {
    @apply --marketplaceButtonStyleSecondaryFill;
    
    font-weight: var(--fontWeightBold);
    font-size: 18px;

    width: 250px;
    min-height: 52px;
    padding: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 18px;
}
