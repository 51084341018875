@import '../../marketplace.css';

.root {
    width: 100%;
    height: 100%;
}

.preTitle {
    color: var(--matterColor);
    font-weight: bolder;
    font-size: 0.8rem;
    text-align: center;
    margin: 0;

    @media (--viewportMedium){
        text-align: left;
    }
  }

.title {
    @apply --marketplaceH1FontStyles;

    color: var(--matterColor);

    text-align: center;
    
    @media (--viewportMedium) {
        font-size: 32px;
        text-align: left;

        margin: 0;
        padding: 0;
    }

    @media (--viewportLarge) {
        font-size: 42px;
        letter-spacing: 0.5px;
    }

    @media (min-width: 1201px) {
        font-size: 46px;
    }
}

.description {
    @apply --marketplaceLandingPageSubtitleFontStyles;
}

.grid {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    padding-bottom: 32px;

    @media (--viewportMedium) {
        grid-template-columns: repeat(2, minmax(45%, 1fr));

        padding-top: 22px;
        padding-bottom: 32px;
    }

    @media (min-width: 1645px) {
        padding-top: 55px;
        padding-bottom: 65px;
    }
}

.image{
    width: 100%;
    padding: 24px;

    @media (--viewportMedium) {
        padding: 48px;
        padding-left: 0;
        padding-right: 12px;
    }
}

.info{
    padding: 24px;

    @media (--viewportMedium) {
        padding: 48px;
        padding-right: 12px;
        margin: auto 0;
    }
}

.text{
    line-height: 1.1em;
    margin-bottom: 24px;
    text-align: center;

    @media (--viewportMedium) {
        text-align: left;
    }
}

.startHosting {
    @apply --marketplaceButtonStyles;

    width: 240px;
    border-radius: 18px;

    background-color: var(--marketplaceSecondaryColor);
    margin: 32px auto 0;

    @media (--viewportMedium) {
        margin: 32px 0 0;
    }

    &:hover {
        background-color: #cf676a;
        color: var(--matterColorLight);
    }
}