@import '../../../marketplace.css';

:root {
    --reference: {
        position: absolute;
        padding: 24px;
        font-size: 0.7em;
    }
}

.root {
    display: flex;
    flex-direction: column-reverse;
    @media (--viewportMedium){
        flex-direction: row;
    }
}

.header {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    color: var(--matterColor);
    text-align: left;
    width: 100%;
    margin-top: 12px;

    @media (--viewportMedium){
        width: 50%;
        margin-right: 96px;
    }
}

.headerTitle{
    @apply --marketplaceH1FontStyles;
    margin: 0;
}

.headerDescription{
    font-weight: 600;
    font-family: var(--secondaryFontFamily);
    font-size: 1.3em;
}

.imageContainer{
    width: 100%;

    @media (--viewportMedium){
        width: 50%;
        margin-left: 96px;
    }
}

.image{
    width: 100%;
}

.circle{
    background-color: var(--matterColorAnti);
    color: var(--matterColorLight);
    padding: 12px;
    width: 48px;
    height: 48px;
    text-align: center;
    border-radius: 100%;
    margin-bottom: 12px;
}